import React from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";
import logo from "../Assets/logo.svg";
import logo_mp from "./assets/logo.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const LogoHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="new_main_header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center new-hearder-main">
            <a className="" href="/">
              <img
                src={logo_mp}
                alt=""
                width="213"
                height="90"
                className="d-inline-block align-text-top h-michel-logo"
              />
            </a>
            <div  className="d-flex justify-content-between align-items-center text-white" 
            style={{ cursor: "pointer", gap:"5px" }} onClick={() => navigate("/")}
            >
              <KeyboardBackspaceIcon /> Go Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoHeader;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Carousel from "react-elastic-carousel";
import html2pdf from "html2pdf.js";
import axios from "axios";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "@mui/material";

import SendMailPopup from "../BussinessPlan/modal/sendMailPopup";
import Loader from "../WebsiteLoader/Index";

import "./new.css";

import MichaelVid from "./assets/Michael_pricharda.mp4";
import MichealImage from "./assets/micheal.png";
import logo from "../Assets/logo.svg";
import logo_mp from "./assets/logo.png";
import cardImage from "../Assets/card.png";
import planImage from "./assets/micImage.png";
import groupImage from "../Assets/Group 9.svg";

import DISC_pdf from "./assets/pdf/disc_pdf.pdf";
import MEYERS_pdf from "./assets/pdf/ENTJ Personality.pdf";
import TRANSCRIPTS_pdf from "./assets/pdf/Behavior Style Report.pdf";
import mp_resume from "./assets/pdf/resume.pdf";

// import MultiStepProgressBar from "./Steppertwo";
import MultiStepProgressBar from "./Stepper";

import $ from "jquery";
import "./new1.css"


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3, itemsToScroll: 1 },
  { width: 996, itemsToShow: 3, itemsToScroll: 1 },
  { width: 1200, itemsToShow: 3 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const New = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [businessPlan, setBusinessPlan] = useState("");
  const [isOpenEmail, setOpenEmail] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);



  const closeMenu = () => {
    setOpenMenu(false);
  };

  const options = {
    items: allData?.length, // Number of items to display
    margin: 10, // Margin between items
    nav: true, // Show navigation arrows
  };

  const ClearData = () => {
    setName("");
    setEmail("");
    setNumber("");
    setSubject("");
    setMessage("");
    setLoader("");
  };

  const addFormHandler = async (e) => {
    e.preventDefault();
    setLoader(true);

    const reqbody = {
      name: name,
      email: email,
      number: number,
      subject: subject,
      message: message,
    };

    await axios
      .post("/api/auth/createContact", reqbody, "")
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          toast.success("Send successfully !");
          setLoader(false);
          ClearData("");
        } else {
          setLoader(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getAllData(1);
  }, []);

  const getAllData = async (page) => {
    setLoadingBtn(true);
    await axios
      .get(`/api/auth/getAllBusinessPlan`)
      .then((res) => {
        if (res.status === 200) {
          setAllData(res?.data?.plans);
          setLoadingBtn(false);
        } else {
          setLoadingBtn(false);
          return;
        }
      })
      .catch((e) => {
        setLoadingBtn(false);
        console.log(e);
      });
  };

  const handleOpen = (plan) => {
    setOpenEmail(true);
    setBusinessPlan(plan);
  };

  const handleClose = () => {
    setOpenEmail(false);
  };

  const generatePDF = async () => {
    const element = document.getElementById("html-element");
    const pageBreakElements = element.getElementsByClassName("page-break");
    const pageBreakCount = pageBreakElements.length;
    for (let i = 0; i < pageBreakCount; i++) {
      const pageBreakElement = pageBreakElements[i];
      pageBreakElement.style.pageBreakAfter = "always";
    }

    const pdfData = await element.toDataURL;
    const blob = new Blob([pdfData]);
    const url = URL.createObjectURL(blob);

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `MichaelPricharda.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
    //    // Generate the PDF and convert it to base64
    // const pdfData = await html2pdf().set(opt).from(element).outputPdf();

    // // Convert the PDF data to base64
    // const base64PDF = btoa(pdfData);

    // // Now you have the base64 encoded PDF
    // console.log(base64PDF);
  };

  const BusinessCard = () => {
    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(0);
      }
    };
    const onPrevStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(allData.length);
      }
    };
    return (
      <>
        <Carousel
          breakPoints={breakPoints}
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          disableArrowsOnEnd={false}
          arrowClassName="carousel-arrow"
          prevArrow={<div>Previous</div>}
          nextArrow={<div>Next</div>}
          pagination={false}
        >
          {allData?.length > 0 &&
            allData?.map((row) => (
              <div
                className="item z-999 plan-card"
                key={row?.id}
                style={{ margin: "10px" }}
              >
                <div className="team-data-img">
                  <div className="single-work">
                    <img
                      src={row?.businessImage}
                      // src={planImage}
                      alt="team"
                      className="img-responsive"
                      data-no-retina
                    />
                    <div className="overlay-text center-block">
                      <div className="cases-image-inner">
                        <span className="cases-line top"></span>
                        <span className="cases-line top-right"></span>
                        <span className="cases-line bottom"></span>
                        <span className="cases-line bottom-left"></span>
                        <h6 className="text-white text-uppercase alt-font" style={{maxWidth:"200px"}}>
                          {row?.businessLabel}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio-content px-2">
                    <h5 className="text-yellow mb-0 text-uppercase">
                      {row?.businessName}
                    </h5>
                    <p className="mb-0 text-white fw-600 price-tag">
                      {row?.price}{" "}
                      <small className="fw-600"> / per month</small>{" "}
                    </p>

                    <p className="text-capitalize py-2 ">
                     <div className="plans-description">{row?.description}</div>
                      {/* <div
                        className="text-white fw-600 plans-description"
                        dangerouslySetInnerHTML={{
                          __html: row?.description,
                        }}
                      /> */}


                    </p>
                    <div className="d-flex align-items-center justify-content-center ">
                      <div
                        type="button"
                        className="btn_link me-2 d-flex align-items-center"
                        onClick={() => handleOpen(row)}
                      >
                        <b className="me-2 text-white">Start Now</b>
                        <img
                          src={groupImage}
                          alt="start"
                          height="16"
                          width="16"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      </>
    );
  };

  return (
    <>
      {isLoadingBtn && <Loader />}
      <ToastContainer />
      {/* <!-- START HEADER --> */}
      <header>
        {/* <!--Navigation--> */}
        <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple nav-line">
          {/* <!--Side Menu Button--> */}
          <button className="sidemenu_btn" onClick={() => setOpenMenu(true)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </nav>

        {/* <!--Side Nav--> */}
        <div className={`side-menu  ${openMenu ? "show_menu" : "hidden_menu"}`}>
          <span id="btn_sideNavClose" onClick={() => setOpenMenu(false)}>
            <i class="fa fa-times close-btn" aria-hidden="true"></i>
          </span>
          <div className="inner-wrapper mt-2">
            <nav className="side-nav w-100">
              <div className="">
                <a href="/" title="Logo" className="logo navbar-brand">
                  <img src={logo_mp} alt="logo" />
                </a>
                <ul className="navbar-nav text-capitalize">
                  <li className="nav-item">
                    <a className="nav-link" href="#home" onClick={closeMenu}>
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about" onClick={closeMenu}>
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#timeline"
                      onClick={closeMenu}
                    >
                      Timeline
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#portfolio"
                      onClick={closeMenu}
                    >
                      Portfolio
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#video"
                      onClick={closeMenu}
                    >
                      Video
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact" onClick={closeMenu}>
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/terms-conditions">
                      term & conditions
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-center sidebar_btn ">
                <a
                  href={mp_resume}
                  download="Michael Pricharda Resume-pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-medium btn-rounded btn-yellow text-capitalize oswald-font "
                >
                  DOWNLOAD RESUME
                </a>
              </div>
            </nav>

            <div className="side-footer w-100">
              <ul className="social-icons-simple pl-0">
                <li>
                  <a
                    className="social-icon"
                    target="blank"
                    href="https://www.facebook.com/michael.pricharda"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="social-icon"
                    target="blank"
                    href="https://www.instagram.com/thecocreategroup/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="social-icon"
                    target="blank"
                    href="https://www.linkedin.com/in/michael-pricharda-791399234"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
              <p className="mt-2 text-center">
                © 2024 Michael Pricharda. | All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
        {/* <a id="close_side_menu" href="javascript:void(0);"></a> */}
      </header>
      {/* <!-- END HEADER --> */}

      <div id="pagepiling" className="page-piling mobile-bg">
        {/* <!-- START BANNER --> */}
        <section className="section slide1 p-0" id="home">
          <div className="slider-area" id="slider-area">
            <div className="row align-items-center banner-row z-999">
              <div className="col-12 col-lg-6 text-md-center text-lg-left d-flex justify-content-center align-items-center">
                <div className="slider-content">
                  <h1 className="main-font text-uppercase pt-2">
                    <span className="slider-text px-4"> Hello!</span>
                    <span
                      className="d-block text-yellow mb-0 mt-4"
                      style={{ whitespace: "nowrap" }}
                    >
                      I am Michael Pricharda
                    </span>
                  </h1>
                  <p className="pt-lg-3 pt-md-3  alt-font content-para text-white">
                    Using modern technology to customize solutions to bridge the
                    gaps in growth margins. Expertise entails creating digital
                    assets, developing different profile-raising strategies,
                    identifying new opportunities, and opening new channels to
                    create an ideal customer/community network. Skilled in
                    integrating various elements to design highly engaging and
                    target-audience-centric content for digital/online spaces
                    and platforms, embodying clients' vision to increase market
                    share and profitability.
                  </p>
                </div>
              </div>

              <div className="col-12 col-lg-6 image-order">
                <div className="slider-image">
                  <img src={MichealImage} alt="Slider-Image" />
                </div>
              </div>
            </div>

            {/* <!-- Circle-One --> */}
            <div className="circle-one">
              <span
                data-tootik="About"
                data-tootik-conf="right dark square shadow"
              >
                <a href="#about">
                  <span className="animated-circle position-relative"></span>
                </a>
              </span>
            </div>
            {/* <!-- Circle-Two --> */}
            <div className="circle-two">
              <span
                data-tootik="Timeline"
                data-tootik-conf="left dark square shadow"
              >
                <a href="#timeline">
                  <span className="animated-circle position-relative"></span>
                </a>
              </span>
            </div>
            {/* <!-- Circle-Three --> */}
            <div className="circle-three">
              <span
                data-tootik="Portfolio"
                data-tootik-conf="top dark square shadow"
              >
                <a href="#portfolio">
                  <span className="animated-circle position-relative"></span>
                </a>
              </span>
            </div>
            {/* <!-- Circle-Four --> */}
            <div className="circle-four">
              <span
                data-tootik="Video"
                data-tootik-conf="bottom dark square shadow"
              >
                <a href="#video">
                  <span className="animated-circle position-relative"></span>
                </a>
              </span>
            </div>
            {/* <!-- Circle-Five --> */}
            <div className="circle-five">
              <span
                data-tootik="Contact"
                data-tootik-conf="bottom dark square shadow"
              >
                <a href="#contact">
                  <span className="animated-circle position-relative"></span>
                </a>
              </span>
            </div>
          </div>
        </section>
        {/* <!-- END BANNER --> */}

        {/* <!-- START SKILLS --> */}
        <section className="section slide2 skills" id="about">
          <div className="container">
            <div className="slider_default_padding">
              <div className="row ">
                <div className="col-12 col-md-7 pl-md-0 z-999 mb-4">
                  <h3 className="main-font text-uppercase">
                    <span className="text-yellow d-block">
                      {" "}
                      GROWTH STRATEGIST
                    </span>{" "}
                    <span className="text-white"> DIGITAL UTILITY EXPERT </span>
                  </h3>
                  <p className="alt-font text-white exp_para pt-2">
                    Specialist in Digital Marketing, Branding and AI Utility.
                  </p>
                </div>
                <div className="col-12 col-md-5 text-md-right pt-4 pt-md-0 d-flex flex-column justify-content-center align-items-center align-items-md-end z-999">
                  <h2 className="m-0 text-yellow main-font">20+</h2>
                  <p className="alt-font text-white fw-600">
                    Years of Experience
                  </p>
                </div>
              </div>
              <div className="row pt-md-3 pt-xl-5 skill-box ">
                <div className="about-skills z-999">
                  <div className="skills-rws row">
                    {/* <!-- First Skill --> */}
                    <div className="skills-rws-colss col-lg-4 col-md-6 col-12">
                      <h4 className="main-font text-yellow skill">
                        Growth Strategy
                      </h4>
                      <p className="alt-font text-white  ">
                        Responsible for developing and implementing strategic
                        plans and initiatives aimed at expanding a company's
                        market presence, increasing revenue, and maximizing
                        overall business growth...
                      </p>
                    </div>
                    {/* <!-- Second Skill --> */}
                    <div className="skills-rws-colss col-lg-4 col-md-6 col-12">
                      <h4 className="main-font text-yellow skill">
                        AI Solutions
                      </h4>
                      <p className="alt-font text-white  ">
                        Accomplished AI Trainer, Analyst, and Integration
                        Specialist skilled in developing and implementing AI
                        solutions. Proficient in UI/UX design, content creation,
                        and ethical considerations in AI applications...
                      </p>
                    </div>
                    {/* <!-- third Skill --> */}
                    <div className="skills-rws-colss col-lg-4 col-md-6 col-12">
                      <h4 className="main-font text-yellow skill">
                        IT Solutions{" "}
                      </h4>
                      <p className="alt-font text-white  ">
                        Proficient in diverse non-technical IT and IoT
                        solutions. Skilled in IT support, UX evaluation, project
                        & asset management, user training, vendor relations, and
                        data analysis...
                      </p>
                    </div>
                    {/* <!-- Fourth Skill --> */}
                    <div className="skills-rws-colss col-lg-4 col-md-6 col-12">
                      <h4 className="main-font text-yellow skill">Finance</h4>
                      <p className="alt-font text-white  ">
                        Experienced in exploring and managing personal
                        investments, tracking market trends, and making informed
                        financial decisions for personal growth and
                        wealth-building...
                      </p>
                    </div>
                    {/* <!-- fifth Skill --> */}
                    <div className="skills-rws-colss col-lg-4 col-md-6 col-12">
                      <h4 className="main-font text-yellow skill">Marketing</h4>
                      <p className="alt-font text-white  ">
                        Versatile marketing specialist skilled in event,
                        experiential, digital, and millennial marketing, with a
                        talent for crafting compelling content, overseeing
                        impactful social media campaigns, and leveraging data
                        analytics for strategic decisions...
                      </p>
                    </div>
                    {/* <!-- sixth Skill --> */}
                    <div className="skills-rws-colss col-lg-4 col-md-6 col-12">
                      <h4 className="main-font text-yellow skill">
                        Development
                      </h4>
                      <p className="alt-font text-white  ">
                        Experienced in driving innovation to achieve market
                        advantages, expand customer reach, and cultivate
                        adaptability, resulting in enhanced differentiation,
                        efficiency, and long-term business sustainability...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- END SKILLS --> */}

        {/* <!-- START TIMELINE --> */}
        <section className="section slide3 timeline-bg" id="timeline">
          <div className="container">
            <div className="slider_default_padding">
              <div className="row heading-row z-999 ">
                <div className="col-12 col-md-7 z-999 mb-5">
                  <h3 className="main-font text-uppercase">
                    <span className="text-yellow d-block text-uppercase">
                      My Career
                    </span>
                    <span className="text-white"> History & Timeline </span>
                  </h3>
                </div>
              </div>
              <MultiStepProgressBar />
            </div>
          </div>
        </section>
        {/* <!-- END TIMELINE --> */}

        {/* <!-- START PORTFOLIO --> */}
        <section className="section slide4 portfolio-bg" id="portfolio">
          <div className="container">
            <div className="slider_default_padding">
              <div className="row heading-row ">
                <div className="col-12 col-md-7 z-999 mb-4 ">
                  <h3 className="main-font text-uppercase mb-1">
                    <span className="text-yellow d-block">
                      {" "}
                      PROJECT PORTFOLIO
                    </span>{" "}
                    <span className="text-white">INVEST WITH ME </span>
                  </h3>
                  <p className="pt-2 alt-font fw-600 text-white">
                    Accredited Investors Only
                  </p>
                </div>
                <div className="col-12 col-md-5 text-md-right text-xs-center  pt-4 pt-md-0 d-flex flex-column justify-content-center z-999">
                  <h2 className="m-0 text-yellow main-font">5</h2>
                  <p className="alt-font fw-600 text-white">Projects</p>
                </div>
              </div>
              <div className="z-999">
                <BusinessCard />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- END PORTFOLIO --> */}

        {/* <!-- START Video --> */}

        <section className="section slide6 testimonial-bg" id="video">
          <div className="container">
            <div className="row pt-3 michael-video">
              <video width="auto" height="auto" controls>
                <source src={MichaelVid} type="video/mp4" />
              </video>
            </div>
            <div className="text-center bt-fix mt-4">
              <a href="#contact">
                {" "}
                <button className="btn btn-medium btn-rounded btn-red text-uppercase w-100 px-2 oswald-font ">
                  CONNECT WITH ME
                </button>{" "}
              </a>
            </div>
          </div>
        </section>
        {/* <!-- END Video --> */}

        {/* <!-- START CONTACT --> */}
        <section className="section slide5 contact-bg" id="contact">
          <div className="container">
            <form
              className="contact-form"
              id="contact-form-data"
              onSubmit={addFormHandler}
            >
              <div className="row pt-3">
                {/* <!--Left Column--> */}

                <div className="col-md-6 z-999 ">
                  <h3 className="main-font text-uppercase mb-4">
                    <span className="text-yellow d-block text-uppercase ">
                      LET'S CONNECT
                    </span>
                  </h3>

                  <div className="form-group mt-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      id="first_name"
                      name="firstName"
                      value={name}
                      maxLength={`64`}
                      pattern="^(?!\s)[a-zA-Z ]{1,}$"
                      title="Please enter valid name"
                      onChange={(e) => setName(e.target.value)}
                      style={{textTransform:"capitalize "}}
                      required
                    />
                  </div>
                  <div className="form-group mt-4">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="userEmail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      title="abc@abc.com"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Contact No"
                      id="phone"
                      name="userPhone"
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                      pattern="[0-9]*"
                      maxLength={10}
                      title="Please enter valid  number"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      type="subject"
                      placeholder="Subject"
                      id="subject"
                      name="subject"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                      minLength={3}
                      maxLength={250}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Type Your Message Here"
                      id="message"
                      name="userMessage"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      maxLength={300}
                      required
                    ></textarea>
                  </div>
                  <div className="contact-btn pt-4 text-lg-left">
                    <button
                      type="submit"
                      className="btn btn-medium btn-rounded btn-yellow text-capitalize oswald-font "
                    >
                      CONTACT NOW
                    </button>
                  </div>
                </div>
                {/* <!--Right Column--> */}
                <div className="col-12 col-md-6  contact_right_box bo-bf">
                  <div className="right_inner_box">
                    <h3 className="main-font mb-5">
                      <span
                        className="text-yellow d-block text-uppercase"
                        style={{ whitespace: "nowrap" }}
                      >
                        resources
                      </span>
                      <span className="text-white" style={{ whiteSpace: "nowrap" }}>
                        Downloadable file
                      </span>
                    </h3>

                    <div className="pdf_btns mt-4">
                      <a
                        href={mp_resume}
                        download="Michael Pricharda Resume"
                        target="_blank"
                        rel="noreferrer"
                        className="w-100"
                      >
                        <button
                          type="button"
                          className="btn btn-medium btn-rounded btn-red text-uppercase oswald-font lets-connect-btn "
                        >
                          Resume
                        </button>
                      </a>

                      <a
                        href={DISC_pdf}
                        download="DISC-Strengths&Values"
                        target="_blank"
                        rel="noreferrer"
                        className="w-100"
                      >
                        <button
                          type="button"
                          className="btn btn-medium btn-rounded btn-red text-uppercase oswald-font lets-connect-btn "
                        >
                          DISC
                        </button>
                      </a>

                      <a
                        href={MEYERS_pdf}
                        download="MEYERS"
                        target="_blank"
                        rel="noreferrer"
                        className="w-100"
                      >
                        <button
                          type="button"
                          className="btn btn-medium btn-rounded btn-red text-uppercase oswald-font lets-connect-btn "
                        >
                          meyers briggs
                        </button>
                      </a>

                      <a
                        href={TRANSCRIPTS_pdf}
                        download="Reports"
                        target="_blank"
                        rel="noreferrer"
                        className="w-100"
                      >
                        <button
                          type="button"
                          className="btn btn-medium btn-rounded btn-red text-uppercase oswald-font lets-connect-btn "
                        >
                          Reports
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <!-- Button --> */}
              </div>
            </form>
          </div>
        </section>
        {/* <!-- END CONTACT --> */}
      </div>

      {/* <!-- Start Copyright --> */}
      <div className="slider-bottom">
        <div className="slider-footer w-auto">
          <ul className="social-icons-simple">
            <li>
              <a
                className="social-icon"
                target="blank"
                href="https://www.facebook.com/michael.pricharda"
              >
                <i className="fab fa-facebook-f"></i>{" "}
              </a>
            </li>
            <li>
              <a
                className="social-icon"
                target="blank"
                href="https://www.instagram.com/thecocreategroup/"
              >
                <i className="fab fa-instagram"></i>{" "}
              </a>{" "}
            </li>
            <li>
              <a
                className="social-icon"
                target="blank"
                href="https://www.linkedin.com/in/michael-pricharda-791399234"
              >
                <i className="fab fa-linkedin-in"></i>{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- End Copyright --> */}

      {/* =-======  send Email ========== */}

      <React.Fragment>
        <Modal
          open={isOpenEmail}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }} className="show_detail_popup">
            <SendMailPopup
              closeModal={handleClose}
              businessPlan={businessPlan}
            />
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default New;

import React, { useEffect, useState } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import axios from "axios"; // Make sure to import axios

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "./stepper.css";

const Stepper = (props) => {
  const visiblePageCount = "15";
  const [page, setPage] = useState(0);
  const [isShowId, setShowId] = useState(false);
  const [allData, setAllData] = useState([]);
  const [newArray, setNewArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowMore, setShowMore] = useState(false);
  const [visibleSteps, setVisibleSteps] = useState(4);
  console.log(page);
  console.log(newArray);

  const getTotalSteps = allData?.length - 1;

  const stepPercentage = ((page / (newArray?.length - 1)) * 100);
  console.log(stepPercentage)

  useEffect(() => {
    getAllData(1, visibleSteps);
  }, []);

  useEffect(() => {
    setNewArray(allData.slice(0, 4));
  }, [allData]);

  const getAllData = async (page, limit) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/career/getAllCareers?page=${page}&limit=${visiblePageCount}`
      );

      if (response.status === 200) {
        setAllData(response?.data?.careers);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const handleNext = () => {
  //   // setShowId(false);
  //   // debugger
  //   if (page + 1 <= getTotalSteps) {
  //     setPage((prevPage) => prevPage + 1);
  //     console.log(page);
  //   }
  //   if (page + 4 <= getTotalSteps) {
  //     setNewArray((prevArray) => [...prevArray, allData[page + 4]]);
  //   }
  // };

  // const handlePrevious = () => {
  //   if (page > 0) {
  //     setPage((prevPage) => prevPage - 1);
  //     console.log(page);
  //   }
  //   if (page >= 4) {
  //     setNewArray((prevArray) => prevArray.slice(0, prevArray.length - 1));
  //   }
  // };


  const handleNext = () => {
    // setShowId(false);
    // debugger
    if (page + 1 <= getTotalSteps) {
      setPage((prevPage) => prevPage + 1);
      console.log(page);
    }
    if (page + 4 <= getTotalSteps) {
      setNewArray((prevArray) => [...prevArray, allData[page + 4]]);
    }
  };

  const handlePrevious = () => {
    console.log(page);
    if (page>0) {
      
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
      console.log(page);
    }
   
    if (page + 3 <= getTotalSteps) {
      setNewArray((prevArray) => prevArray.slice(0, -1));
    }
   
    }
  };
  console.log(getTotalSteps,stepPercentage,page,newArray.length)

  return (
    <>
      <div className="">
        <div className="content z-999 stepper_row">
          <div className=" left_stepper_arrow" onClick={handlePrevious}>
            <NavigateBeforeIcon />
          </div>
          <ProgressBar percent={stepPercentage}>
            {Array.isArray(newArray) &&
              newArray.map((items, inx) => {
                return (
                  <Step key={inx}>
                    {({ accomplished, index }) => (
                      <>
                        <div className="stepper-count text-white">{items?.year}</div>
                        <div
                          className={`indexedStep ${
                            accomplished || page === inx ? "accomplished" : ""
                          }`}
                          // onClick={() => {setPage(inx); setShowId(false); }}
                        ></div>
                      </>
                    )}
                  </Step>
                );
              })}
          </ProgressBar>
          <div className=" right_stepper_arrow" onClick={handleNext}>
            <ChevronRightIcon />
          </div>
        </div>
        <div className="stepper-content mt-5">
          <ol className="ps-0">
            {newArray.map((items, inx) => (
              <li
                key={inx}
                className={
                  page === inx ? "is-show z-999 selected " : "not-show"
                }
              >
                <div className="col-12  z-999 px-0">
                  <div className="main-content text-uppercase career-text">
                    <h2 className="text-yellow main-font font-weight-normal">
                      {items?.mainHeading}
                    </h2>
                    <h4 className="main-font">{items?.subHeading}</h4>
                  </div>
                </div>
                {/* ... */}
                <div className="row px-0">
                  <div className="col-12 col-md-4 ">
                    <div className="sub-content">
                      <h5 className="text-uppercase main-font text-yellow">
                        Major subjects
                      </h5>

                      <ul className="pl-0">
                        {items?.majorSubjects?.map((list, i) => (
                          <li key={i} className="text-white">
                            {list}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="sub-content">
                      <h5 className="text-uppercase main-font text-yellow">
                        My Achievements
                      </h5>
                      <ul className="pl-0">
                        {items?.myAchievement?.map((list, idx) => (
                          <li key={idx} className="text-white">
                            {list}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 see_less_para">
                    <div className="sub-content ">
                      <h5 className="text-uppercase main-font text-yellow">
                        What I learned
                      </h5>

                      <ul className="pl-0">
                        {items?.whatILearned?.length > 3 ? (
                          <>
                            {isShowId ? (
                              <>
                                <div className="see_more_para">
                                  {items?.whatILearned?.map((list) => (
                                    <li className="text-white">{list}</li>
                                  ))}
                                  <div
                                    className="alt-font text-yellow z-999"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowId(false)}
                                  >
                                    See less..
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {items?.whatILearned
                                  ?.slice(0, 3)
                                  ?.map((list, ixn) => (
                                    <li className="text-white" key={ixn}>
                                      {list}
                                    </li>
                                  ))}
                                <div
                                  className="alt-font text-yellow z-999"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShowId(true);
                                  }}
                                >
                                  See more..
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {items?.whatILearned?.map((list) => (
                              <li className="text-white">{list}</li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
};

export default Stepper;

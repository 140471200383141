import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import { Box, Modal, Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../../WebsiteLoader/Index";

import style from "../../Css/admin.module.css";
import Pagination from "./Pagination/Index";

import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

const CareerPage = () => {
  const visiblePageCount = "5";
  const [editId, setEditId] = useState("");
  const [allData, setallData] = useState([]);
  const [isRefresh, setRefresh] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAddPopup, setAddPopup] = useState(false);
  const [isDeletePopup, setDeletePopup] = useState(false);

  const [subject, setSubject] = useState("");
  const [majorSubjects, setMajorSubjects] = useState([]);

  const [achievement, setAchievement] = useState("");
  const [myAchievement, setMyAchievement] = useState([]);

  const [learning, setLearning] = useState("");
  const [whatILearned, setWhatILearned] = useState([]);

  const [careerDetails, setCarrerDetails] = useState({
    year: "",
    mainHeading: "",
    subHeading: "",
  });

  const closeAddModal = () => {
    setAddPopup(false);
    refreshData();
  };

  const refreshData = () => {
    setRefresh(!isRefresh);
    setCarrerDetails({
      year: "",
      mainHeading: "",
      subHeading: "",
    });
    setMajorSubjects([]);
    setMyAchievement([]);
    setWhatILearned([]);
  };

  const inputhandler = (e) => {
    const { name, value } = e.target;
    setCarrerDetails({
      ...careerDetails,
      [name]: value,
    });
  };

  // add new business plan
  const addFormHandler = async (e) => {
    const reqBody = {
      year: careerDetails.year,
      mainHeading: careerDetails.mainHeading,
      subHeading: careerDetails.subHeading,
      majorSubjects: majorSubjects,
      myAchievement: myAchievement,
      whatILearned: whatILearned,
    };

    e.preventDefault();

    setLoader(true);
    await axios
      .post("/api/career/addCareer", reqBody, "")
      .then((res) => {
        if (res.status === 201) {
          toast.success("Added Successfully !");
          setLoader(false);
          setAddPopup(false);
          refreshData();
        } else {
          setLoader(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const handleDeletePopup = (id) => {
    setEditId(id);
    setDeletePopup(true);
  };

  // delete business plan
  const deleteHandler = async (id) => {
    setLoader(true);
    await axios
      .post("/api/career/deleteCareer", { _id: id }, "")
      .then((res) => {
        if (res.status === 200) {
          toast.success("Deleted Successfully !");
          setLoader(false);
          refreshData();
          setDeletePopup(false);
        } else {
          setLoader(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getAllData(1);
  }, [isRefresh]);

  const getAllData = async (page) => {
    setLoading(true);
    await axios
      .get(`/api/career/getAllCareers?page=${page}&limit=${visiblePageCount}`)
      .then((res) => {
        if (res.status === 200) {
          setallData(res?.data);
          console.log("res", res);
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleAdd = () => {
    setMajorSubjects([...majorSubjects, subject]);
    setSubject("");
  };

  const handleDelete = (id) => {
    const newArr = majorSubjects?.filter((subList, idx) => idx !== id);
    setMajorSubjects(newArr);
  };

  const handleAddAchivement = () => {
    setMyAchievement([...myAchievement, achievement]);
    setAchievement("");
  };

  const handleDeleteAchivement = (id) => {
    const newArr = myAchievement?.filter((subList, idx) => idx !== id);
    setMyAchievement(newArr);
  };

  const handleAddLearning = () => {
    setWhatILearned([...whatILearned, learning]);
    setLearning("");
  };

  const handleDeleteLearning = (id) => {
    const newArr = whatILearned?.filter((subList, idx) => idx !== id);
    setWhatILearned(newArr);
  };

  return (
    <>
      {loading && <Loader />}
      <>
        <section className="p-md-4 p-2 ">
          <div className={style.top_section}>
            <h6 className="mb-0"> Careers Data </h6>
            <button className="btn btn-dark" onClick={() => setAddPopup(true)}>
              Add new
            </button>
          </div>

          <div className="px-2 w-100 pt-4 dashboard_table">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <td>No.</td>
                  <td>Year</td>
                  <td>Main Heading</td>
                  <td>Sub Heading</td>
                  <td>Major Subjects</td>
                  <td>My Achievement</td>
                  <td>What I Learned</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {allData?.careers?.length > 0
                  ? allData?.careers?.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1 + (allData?.page - 1) * 5}</td>
                          <td>{items?.year}</td>
                          <td><div className="tables_row">{items?.mainHeading}</div></td>
                          <td><div className="tables_row">{items?.subHeading}</div></td>
                          <td>
                            {items?.majorSubjects &&
                            items?.majorSubjects.length > 1 ? (
                              <ul>
                                {items?.majorSubjects.map((list, inx) => {
                                  return <li key={inx}>{list}</li>;
                                })}
                              </ul>
                            ) : (
                              items?.majorSubjects
                            )}
                          </td>
                          <td>
                            {items?.myAchievement &&
                            items?.myAchievement.length > 1 ? (
                              <ul>
                                {items.myAchievement.map((list, inx) => {
                                  return <li key={inx}>{list}</li>;
                                })}
                              </ul>
                            ) : (
                              items.myAchievement
                            )}
                          </td>
                          <td>
                            {items?.whatILearned &&
                            items?.whatILearned?.length > 1 ? (
                              <ul>
                                {items?.whatILearned?.map((list, inx) => {
                                  return <li key={inx}>{list}</li>;
                                })}
                              </ul>
                            ) : (
                              items?.whatILearned
                            )}
                          </td>
                          <td className="">
                            <div className="d-flex justify-content-start">
                              <div className="ms-2">
                                <button
                                  className="btn btn-danger w-100"
                                  onClick={() => handleDeletePopup(items?._id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
            <ToastContainer />
          </div>
        </section>
        {allData?.totalPages > 1 && (
          <Pagination
            currentpage={allData?.page}
            totalCount={allData?.totalPages}
            visiblePageCount={visiblePageCount}
            getAllData={getAllData}
          />
        )}
      </>

      {/* =-====== add popup ========== */}
      <React.Fragment>
        <Modal
          open={isAddPopup}
          onClose={() => setAddPopup(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...modalStyle }} className="custom_mui_modal">
            <div className="px-5 py-5">
              <form action="" onSubmit={addFormHandler}>
                <div className="row">
                  <h4 className="text-center  mb-1">Add Careers Data</h4>

                  <div className="col-12 my-2">
                    <input
                      type="text"
                      name="year"
                      value={careerDetails.year}
                      className="custom_input"
                      placeholder="Enter year"
                      onChange={inputhandler}
                      pattern="[0-9]*"
                      title="Enter only numbers"
                      maxLength={4}
                      required
                    />
                  </div>
                  <div className="col-12 my-2">
                    <input
                      type="text"
                      name="mainHeading"
                      value={careerDetails.mainHeading}
                      className="custom_input"
                      placeholder="Enter main heading"
                      onChange={inputhandler}
                      required
                    />
                  </div>
                  <div className="col-12 my-2">
                    <input
                      type="text"
                      name="subHeading"
                      className="custom_input"
                      placeholder="Enter sub heading"
                      value={careerDetails.subHeading}
                      onChange={inputhandler}
                      required
                    />
                  </div>

                  {/*-------- major subject list start ------*/}
                  <div className="col-10 my-2">
                    <input
                      type="text"
                      className="custom_input"
                      placeholder="Enter Major subject section data"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                  <div className="col-2 my-2">
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={handleAdd}
                    >
                      +
                    </button>
                  </div>

                  <div className="col-12 my-2">
                    <div className="custom_input custom_input_div p-2">
                      {majorSubjects?.map((sub, inx) => (
                        <div className="d-flex  align-items-center">
                          <div
                            onClick={() => handleDelete(inx)}
                            style={{ cursor: "pointer" }}
                          >
                            <DoDisturbOnIcon
                              style={{ height: "16px", width: "16px" }}
                            />
                          </div>
                          <p className="mb-0" key={inx}>
                            {sub}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/*-------- major subject list end ------*/}

                  {/*-------- achievement list start ------*/}
                  <div className="col-10 my-2">
                    <input
                      type="text"
                      className="custom_input"
                      placeholder="Enter My achievement section data"
                      onChange={(e) => setAchievement(e.target.value)}
                      value={achievement}
                    />
                  </div>
                  <div className="col-2 my-2">
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={handleAddAchivement}
                    >
                      +
                    </button>
                  </div>

                  <div className="col-12 my-2">
                    <div className="custom_input custom_input_div p-2">
                      {myAchievement?.map((sub, inx) => (
                        <div className="d-flex  align-items-center">
                          <div
                            onClick={() => handleDeleteAchivement(inx)}
                            style={{ cursor: "pointer" }}
                          >
                            <DoDisturbOnIcon
                              style={{ height: "16px", width: "16px" }}
                            />
                          </div>
                          <p className="mb-0" key={inx}>
                            {sub}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/*--------achievement list end ------*/}

                  {/*-------- achievement list start ------*/}
                  <div className="col-10 my-2">
                    <input
                      type="text"
                      className="custom_input"
                      placeholder="Enter what I learned section data"
                      onChange={(e) => setLearning(e.target.value)}
                      value={learning}
                    />
                  </div>
                  <div className="col-2 my-2">
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={handleAddLearning}
                    >
                      +
                    </button>
                  </div>

                  <div className="col-12 my-2">
                    <div className="custom_input custom_input_div p-2">
                      {whatILearned?.map((sub, inx) => (
                        <div className="d-flex  align-items-center">
                          <div
                            onClick={() => handleDeleteLearning(inx)}
                            style={{ cursor: "pointer" }}
                          >
                            <DoDisturbOnIcon
                              style={{ height: "16px", width: "16px" }}
                            />
                          </div>
                          <p className="mb-0" key={inx}>
                            {sub}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/*--------achievement list end ------*/}

                  <div className="row justify-content-center align-items-center mt-3">
                    <div className="col-md-6 col-12 mt-2">
                      <button
                        type="button"
                        className="btn btn-outline-dark w-100"
                        onClick={closeAddModal}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-6 col-12 mt-2">
                      {isLoader ? (
                        <button
                          type="button"
                          className="btn inactive_btn w-100"
                        >
                          Loading..
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-dark w-100">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Box>
        </Modal>
      </React.Fragment>

      {/* =-====== delete popup ========== */}
      <React.Fragment>
        <Modal
          open={isDeletePopup}
          onClose={() => setDeletePopup(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...modalStyle }} className="custom_mui_del_modal">
            <div className="mt-2 w-100 px-5 py-5">
              <h5 className="text-center pb-4">
                Are you sure you want to delete this Plan?
              </h5>
              <div className="row justify-content-center align-items-center mt-1">
                <div className="col-md-4 col-6 mt-2">
                  <button
                    onClick={() => setDeletePopup(false)}
                    className="w-100 btn btn-outline-dark"
                  >
                    No
                  </button>
                </div>
                <div className="col-md-4 col-6 mt-2">
                  {isLoader ? (
                    <LoadingButton
                      loading
                      disabled
                      variant="contained"
                      style={{ background: "#f3f3f3 ", width: "100%" }}
                    >
                      Yes
                    </LoadingButton>
                  ) : (
                    <button
                      onClick={() => deleteHandler(editId)}
                      className="w-100 btn btn-dark"
                    >
                      Yes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default CareerPage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";

import Animated from "./animated";
import "./resume.css";

import engineeringIcon from "./assets/engineering.svg";
import finance from "./assets/finance.svg";
import reading from "./assets/reading.svg";
import technology from "./assets/technology.svg";
import callIcon from "./assets/call.svg";
import message from "./assets/message.svg";
import location from "./assets/location.svg";

const ResumeDownloded = () => {
  const navigate = useNavigate();
  useEffect(() => {
    generatePDF();
  });
  const generatePDF = async () => {
    const element = document.getElementById("html-element");
    const pageBreakElements = element.getElementsByClassName("page-break");
    const pageBreakCount = pageBreakElements.length;
    for (let i = 0; i < pageBreakCount; i++) {
      const pageBreakElement = pageBreakElements[i];
      pageBreakElement.style.pageBreakAfter = "always";
    }

    const pdfData = await element.toDataURL;
    const blob = new Blob([pdfData]);
    const url = URL.createObjectURL(blob);

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `MichaelPricharda.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
    //    // Generate the PDF and convert it to base64
    // const pdfData = await html2pdf().set(opt).from(element).outputPdf();

    // // Convert the PDF data to base64
    // const base64PDF = btoa(pdfData);

    // // Now you have the base64 encoded PDF
    // console.log(base64PDF);
    navigate("/resume");
  };
  return (
    <section className="resume_download">
      <div className="container">
        <div className="" id="html-element">
          <div className="top_section">
            <h1>
              <span className="mr-2 text-blue">Michael</span>
              <span>Pricharda</span>
            </h1>
            <p className="digital_utility_expert my-3">
              DIGITAL UTILITY EXPERT
            </p>
            <div className="">
              <h5 className="resume_titles mt-5">PROFILE</h5>
              <p className="resume_para text_md mt-4">
                Uses modern technology to customize solutions to bridge the gaps
                in growth margins. Expertise entails creating digital assets,
                developing different profile-raising strategies, identifying new
                opportunities, and opening new channels to create an ideal
                customer/community network. Skilled in integrating various
                elements to design highly engaging and target-audience-centric
                content for digital/online spaces and platforms, embodying
                clients' vision to increase market share and profitability.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pe-0">
              <div className="dark_bg">
                <div className="row personal_details">
                  <div className="col-12 text_14  mt-3">Height : 6'2"</div>
                  <div className="col-12 text_14 mt-3">Weight : 180lbs</div>
                  <div className="col-12text_14 mt-3">Sex : Male</div>
                  <div className="col-12 text_14 mt-3">Pronouns : He/Him</div>
                </div>
                <div className="education_section pt-4">
                  <h5 className="resume_titles mt-5">EDUCATION</h5>
                  <p className="sub_heading mt-4">
                    International Business & Marketing
                  </p>
                  <p className="resume_para">(Albright Collage 2000-2003)</p>
                </div>
              </div>
              <div className="experties_section">
                <h5 className="resume_titles my-3">EXPERTISE</h5>
                <div
                  id="progress-box"
                  className="row justify-content-center align-items-center"
                >
                  <div className="col-lg-6 col-12 mt-3">
                    <h6 className="line-hov" id="results">
                      Growth Strategy
                    </h6>
                  </div>

                  <div className="col-lg-6 col-12 mt-3 relative">
                    <Animated progressRange={100} />
                  </div>

                  <div className="col-lg-6 col-12 mt-2">
                    <h6 className="line-hov" id="results">
                      Innovation
                    </h6>
                  </div>
                  <div className="col-lg-6 col-12 mt-2 mt-lg-0">
                    <Animated progressRange={60} />
                  </div>

                  <div className="col-lg-6 col-12 mt-2">
                    <h6 className="line-hov" id="results">
                      AI Solutions
                    </h6>
                  </div>
                  <div className="col-lg-6 col-12 mt-2 mt-lg-0">
                    <Animated progressRange={50} />
                  </div>
                  <div className="col-lg-6 col-12 mt-2">
                    <h6 className="line-hov" id="results">
                      IT Solutions
                    </h6>
                  </div>
                  <div className="col-lg-6 col-12 mt-2 mt-lg-0">
                    <Animated progressRange={80} />
                  </div>
                  <div className="col-lg-6 col-12 mt-2">
                    <h6 className="line-hov" id="results">
                      Marketing
                    </h6>
                  </div>
                  <div className="col-lg-6 col-12 mt-2 mt-lg-0">
                    <Animated progressRange={50} />
                  </div>
                </div>
              </div>
              <div className="interest_section">
                <h5 className="resume_titles my-3">Interests</h5>
                <div className="row justify-content-between">
                  <div className="col-12 mt-4">
                    <div className="d-flex align-items-center">
                      <img src={finance} alt="" />
                      <p className="interest_para">Finance</p>
                    </div>
                  </div>
                  <div className="col-12  mt-3">
                    <div className="d-flex align-items-center">
                      <img src={technology} alt="" />
                      <p className="interest_para">Technology</p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="d-flex align-items-center">
                      <img src={engineeringIcon} alt="" />
                      <p className="interest_para">Engineering</p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="d-flex align-items-center">
                      <img src={reading} alt="" />
                      <p className="interest_para">Reading</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="left_bottom">
                <div className="d-flex ">
                  <div className="img_box">
                    <img src={callIcon} alt="" />
                  </div>
                  <div className="ms-2 mb-4">
                    <p className="strong mb-0">Call me!</p>
                    <small>1 (866)-376-1970</small>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="img_box">
                    <img src={message} alt="" />
                  </div>
                  <div className="ms-2 mb-4">
                    <p className="strong mb-0">Email me!</p>
                    <small>mpricharda@gmail.com</small>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="img_box">
                    <img src={location} alt="" />
                  </div>
                  <div className="ms-2 mb-4">
                    <p className="strong mb-0">Send me coupons!</p>
                    <small>Reading, PA, USA</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 ps-0">
              <div className="">
                <div className="r-left-dive">
                  <div className="px-4 py-2">
                    <p className="resume_titles pt-5">WORK EXPERIENCE</p>
                    {/* <div className="r-blue-bar"></div> */}
                    <p className="r-ceo text_md mt-4">CEO, President</p>
                    <div className="d-flex">
                      <div className="r-current">
                        <p className="r-current-t text_sm">2023 &gt; Current</p>
                      </div>
                      <p className="r-trained text_md">Trained O.S.A.I.</p>
                    </div>
                    <p className="resume_para  text_14">
                      Defined company's vision and strategy, driving the
                      development of AI technologies that address industry
                      challenges. Secured multiple high-profile sponsors,
                      fostering strategic partnerships. Assembled a dynamic team
                      of experts, fostering a collaborative environment that’s
                      propelled the company's innovation. Navigate complex
                      regulatory landscapes and ethical considerations, ensuring
                      the company's AI solutions adhere to the highest standards
                      of responsibility and transparency.
                    </p>
                  </div>
                </div>
                <div className="r-left-dive py-1 mt-3">
                  <div className="px-4 ">
                    <p className="r-ceo-e  text_md ">
                      Executive Director, President
                    </p>
                    <div className="d-flex">
                      <div className="r-current">
                        <p className="r-current-t-2 text_sm">
                          2014 &gt; Current
                        </p>
                      </div>
                      <p className="r-trained text_md">The CoCreate Group</p>
                    </div>
                    <p className="resume_para">
                      Pioneered nonprofit initiative guiding startups and
                      nonprofits through their early years. Orchestrated
                      development of unique programs and resources, equipping
                      emerging organizations with tools, mentorship, and
                      strategies needed to thrive. Forged strategic
                      partnerships, vertically integrating with companies to
                      broaden impact, and drive collective success.
                    </p>

                    <p className="r-ceo-g text_14 ">
                      Growth, IT, Strategy Consultant
                    </p>
                    <div className="d-flex">
                      <div className="r-current">
                        <p className="r-current-t text_sm">2003 &gt; Current</p>
                      </div>
                      <p className="r-trained text_md">DBA Michael Pricharda</p>
                    </div>
                    <p className="resume_para ">
                      Championed integrating growth strategies with innovative
                      IT solutions to optimize operational efficiency and
                      bottom-line results. Pioneered digital, event, and
                      experiential marketing campaigns, consistently exceeding
                      client expectations. Served as a trusted advisor to
                      leadership teams, offering actionable insights to drive
                      business success. Honed skills and expertise to stay at
                      the forefront of industry trends and emerging technologies
                      to provide clients with up-to-date and effective
                      solutions.
                    </p>

                    <div className="">
                      <h3 className="skills text_md ">SKILLS</h3>
                      {/* <div className="r-blue-bar"></div> */}
                      <div className=" skills-list  resume_lists">
                        <div className="list-icons ">
                          <ul id="list" className="ul-list">
                            <li id="list">
                              Advertising / Branding / Digital Marketing
                            </li>
                            <li id="list">Budgeting, Expense Planning</li>
                            <li id="list">Campaign Design and Management</li>
                            <li id="list">Collaborative Communication</li>
                            <li id="list">Consultative Sales</li>
                            <li id="list">Cost Control</li>
                            <li id="list">Critical Thinking & Analysis</li>
                            <li id="list">Customer Care</li>
                            <li id="list">Event Management</li>
                            <li id="list">Independent and Teamwork</li>
                            <li id="list">Lean Management</li>
                            <li id="list">Operations Management</li>
                          </ul>
                        </div>
                        <div className="list-icons">
                          <ul id="list" className="ul-list">
                            <li id="list">Presentations</li>
                            <li id="list">PR & Negotiation</li>
                            <li id="list">Process Improvement </li>
                            <li id="list"> Problem-Solving </li>
                            <li id="list">Project Ownership</li>
                            <li id="list">
                              {" "}
                              Public Speaking & Relationship Building
                            </li>
                            <li id="list"> SEO / SMO</li>
                            <li id="list">
                              {" "}
                              Strategic Planning and Implementing{" "}
                            </li>
                            <li id="list">
                              {" "}
                              Supervision & Activities Coordination
                            </li>
                            <li id="list"> Team Development</li>
                            <li id="list">Time Management</li>
                            <li id="list">
                              Workload Prioritization & Multitasking
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResumeDownloded;

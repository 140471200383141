import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import card from "../Assets/card.png";
import group from "../Assets/Group 9.svg";
import victor2 from "../Assets/Vector 1.png";
import axios from "axios";
import { AppSync } from "aws-sdk";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const BuyPlan = () => {
  const [isPopup, setPopop] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isCodeSubmited, setCodeSubmited] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [businessPlanDetail, setBusinessPlanDetail] = useState([]);
  const { mail, businessPlan } = useParams();

  const handleClick = () => {
    setPopop(true);
  };

  const submitAccessCode = async (e) => {
    e.preventDefault();

    const reqbody = {
      Accesskey: accessCode,
      email: mail,
    };

    setLoader(true);
    await axios
      .post("/api/auth/codeSubmission", reqbody, "")
      .then((res) => {
        if (res.status === 200) {
          setPopop(false);
          setLoader(false);
          setAccessCode("");
          toast.success("Code submitted successfully!");
          setCodeSubmited(res?.status);
        } else if (res?.status === 404) {
          toast.error("Something went wrong, try later");
          setAccessCode("")
        } else {
          setLoader(false);
          toast.error("Something went wrong, try later");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        toast.error("Wrong access code");
        console.log(e);
        setAccessCode("")
      });
  };

  useEffect(() => {
    getAllBusinessPlan();
  }, []);

  const getAllBusinessPlan = async () => {
    await axios
      .get(`/api/auth/getAllBusinessPlan`)
      .then((res) => {
        if (res.status === 200) {
          const businessNames = res?.data?.plans?.filter(
            (row) => row?._id === businessPlan
          );

          setBusinessPlanDetail(businessNames);
        } else {
          return;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <section className="business_main  justify-content-center align-items-center text-center py-50">
        <div className="container">
          <div className="bussiness_head row justify-content-center">
            <div className="col-lg-8 col-md-10 col-12">
              <h5 className="py-3 ">Choose your business plan</h5>
              <p className="pb-5 mb-0">
                A serial entrepreneur must find resourceful ways to bootstrap!
                If you are an accredited investor who has intentionally visited
                this page please request an access code for the business plan
                you wish to view by selecting the plan and providing an email.
              </p>
          <div className="row justify-content-center  align-items-center pb-md-5 bplan_cards">
            <div className="buy_plan_card col-lg-6 col-md-8 col-11 py-3">
              <div className="card_img">
                <img src={card} alt="plan" className="business_img" />
                <div className="card_label">
                  {businessPlanDetail[0]?.businessLabel}
                </div>
                <div className="card_price ">
                  <p className="text_1 mt-5">
                    {businessPlanDetail[0]?.businessName}
                  </p>
                  <p className="mb-0 text_2">{businessPlanDetail[0]?.price} </p>
                  <small>per month</small>
                </div>
              </div>
              <div className="mx-1">
                <div className="text-start mt-4">
                  <div className="pt-4 card_desc">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: businessPlanDetail[0]?.description,
                      }}
                    />
                  </div>

                  <div className="d-flex align-items-center my-3 ">
                    {isCodeSubmited ? (
                      <Link to={businessPlanDetail[0]?.base64Pdf} target="blank">
                        <button role="button" className="btn btn-danger">
                          Download PDF
                        </button>
                      </Link>
                    ) : (
                      <button
                        role="button"
                        className="btn btn_link me-2"
                        onClick={handleClick}
                      >
                        <b className="me-2">Enter access code </b>
                        <img src={group} alt="start" height="20" width="20" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </section>

      <React.Fragment>
        <Modal
          open={isPopup}
          onClose={() => setPopop(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }} className="buy-plan-modal">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                onClick={() => setPopop(false)}
              ></button>
              <img src={victor2} style={{ width: "100%" }} />

              <div className="row justify-content-center pb-5">
                <div className="col-10">
                  <div className="" id="B-plan-label-2">
                    Enter your access code here
                  </div>

                  <form action="" className="" onSubmit={submitAccessCode}>
                    <div className="business_row">
                      <input
                        type="text"
                        placeholder="Enter secret code"
                        id="B-plan-field"
                        className="custom_input"
                        value={accessCode}
                        onChange={(e) => setAccessCode(e.target.value)}
                        required
                      />

                      {isLoader ? (
                        <button
                          type="button"
                          className="btn btn-danger buttonload"
                          id="icon-2"
                        >
                          <i className="fa fa-spinner fa-spin mr-2"></i>
                        </button>
                      ) : (
                        <button
                          id="icon-2"
                          data-bs-target="#exampleModalToggle2"
                          type="submit"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
      <ToastContainer />
    </>
  );
};

export default BuyPlan;

import React from "react";
import "../Navbar/style.css";
import logo_mp from "../../New/assets/logo.png";


const navItems = [
  {
    id: 1,
    item: "home",
    path: "/home",
  },
  {
    id: 2,
    item: "resume",
    path: "/resume",
  },
  {
    id: 3,
    item: "business plan",
    path: "/business-plan",
  },
  {
    id: 4,
    item: "contact me",
    path: "/contact",
  },
  {
    id: 5,
    img: true,
    path: "https://www.linkedin.com",
  },
];

const Footer = () => {
  return (
    <>
   <div className="new_main_header">
        <div className="container text-center">
          <a className="" href="/">
            <img
              src={logo_mp}
              alt=""
              width="213"
              height="90"
              className="d-inline-block align-text-top h-michel-logo"
              style={{margin:"0 auto"}}
            />
          </a>
        </div>
      </div>

      <section className="footer_bottom py-4 navbar navbar-expand-lg">
        <div className="container">
          <div className="row justify-content-between fotter-row-1 w-100">
            <div className="col-lg-6 col-12" id="footer-d">
              {" "}
              © 2023, Michael Pricharda. | All Rights Reserved.
            </div>
            <div className="col-lg-6 col-12">
            <ul className="navbar-nav align-items-center justify-content-end mb-0">
                <li className="nav-item list_items px-3"> <a href="/privacy-policy" >Privacy Policy</a> </li>
                <li className="nav-item list_items px-3"> <a href="/terms-conditions" >Terms & Conditions</a></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

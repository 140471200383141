import "./App.css";
import Navbar from "./routes";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <>
      <Navbar />
    </>
  );
}

export default App;

import React, { useState } from "react";
import BuyPlan from "./Component/BuyPlan/Index";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Footer from "./Component/Home/Footer";
import { Admin } from "./Component/AdminModule/Admin";
import { Admin_Login } from "./Component/AdminModule/AdminLogin/Admin";

import NDAAgreement from "./Component/BussinessPlan/Agreement";
import TermsnConditions from "./Component/Home/Content/term&cond";
import PrivacyPolicy from "./Component/Home/Content/privacyPloicy";
import ResumeDownloded from "./Component/Home/Resume/resumeDownload";
import New from "./Component/New/Index";
import LogoHeader from "./Component/New/NewHeader";

function NotFoundPage() {
  return <div className="page">Not Found Page</div>;
}

function HeaderMain() {
  const location = useLocation();

  if (location.pathname === "/admin" || location.pathname === "/admin-login" || location.pathname === "/"   ) {
    return null;
  }

  return <LogoHeader />;
}

function FooterMain() {
  const location = useLocation();

  // Hide footer on /admin and /admin_login routes
  if (location.pathname === "/admin" || location.pathname === "/admin-login" || location.pathname === "/" ) {
    return null;
  }

  return <Footer />;
}

function PrivateRoute({ element, token }) {
  if (token !== null) {
    return element;
  } else {
    return <Navigate to="/admin-login" replace />;
  }
}

export default function Navbar() {
  const [token, setToken] = useState(
    JSON.parse(sessionStorage.getItem("accessToken"))
  );

  return (
    <BrowserRouter>
       <HeaderMain />
      <Routes>
        <Route path="/" element={<New />} />
        {/* <Route
          path="/admin"
          element={<PrivateRoute element={<Admin />} token={token} />}
        /> */}
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin-login" element={<Admin_Login />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/resume-download" element={<ResumeDownloded />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsnConditions />} />
        <Route path="/buy-plan/:mail/:businessPlan" element={<BuyPlan />} />

        <Route
          path="/agreement/:mail/:businessPlan"
          element={<NDAAgreement />}
        />
        <Route path="/">
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
      <FooterMain />
    </BrowserRouter>
  );
}

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import LinearProgress from '@mui/material/LinearProgress';
// import { useState, useEffect, useRef } from 'react';

// export default function Animated({ progressRange }) {
//   const [progress, setProgress] = useState(0);
//   const [isVisible, setIsVisible] = useState(false);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         const entry = entries[0];
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         }
//       },
//       {
//         threshold: 0.5,
//       }
//     );

//     observer.observe(containerRef.current);

//     return () => observer.disconnect();
//   }, []);

//   useEffect(() => {
//     if (isVisible) {
//       const interval = setInterval(() => {
//         setProgress((prevProgress) => {
//           const diff = (progressRange / 100) * 10;
//           const newProgress = prevProgress + diff;

//           if (newProgress >= progressRange) {
//             clearInterval(interval);
//             return progressRange;
//           }

//           return newProgress;
//         });
//       }, 500);

//       return () => clearInterval(interval);
//     }
//   }, [isVisible, progressRange]);

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         overflow: 'hidden', // Hide overflow for animation
//       }}
//       className="animated-container"
//       ref={containerRef}
//     >
//       <Box
//         sx={{
//           transform: isVisible ? 'translateX(0)' : 'translateX(-100%)', // Apply animation
//           transition: 'transform 1s ease', // Add transition
//         }}
//       >
//         <LinearProgress variant="determinate" value={progress} className=" md" />
//       </Box>
//     </Box>
//   );
// }

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function Animated({ progressRange, startAnimation }) {
  const [progress, setProgress] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    scrollFunction();
  }, []);

  const scrollFunction = () => {
    // console.log(progress);
    setProgress(0);
    setHasAnimated(false);
    setIsHovered(false);

    let interval; // Declare interval here

    if (!hasAnimated) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const diff = (progressRange / 100) * 10;
          const newProgress = prevProgress + diff;

          if (newProgress >= progressRange) {
            clearInterval(interval);
            setHasAnimated(true);
            return progressRange;
          }

          return newProgress;
        });
      }, 500);
    } else if (isHovered) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const diff = (progressRange / 100) * 10;
          const newProgress = prevProgress + diff;

          if (newProgress >= progressRange) {
            clearInterval(interval);
            return progressRange;
          }

          return newProgress;
        });
      }, 500);
    }

    return () => {
      clearInterval(interval);
    };
  };
  return (
    <div
      // onMouseEnter={startAnimation}
      onMouseEnter={scrollFunction}
      onMouseLeave={scrollFunction}
    >
      <Box
      // onMouseLeave={() => setIsHovered(false)}
      >
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import style from "../../Css/admin.module.css";
import Loader from "../../WebsiteLoader/Index";
import Pagination from "./Pagination/Index";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};


export const ContactUser = () => {
  const [allData, setallData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("userDetails")) //test this code
  );
  const [isRefresh, setRefresh] = useState(false);
  const [delId, setDelId] = useState("");
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [businessPlan, setBusinessPlan] = useState();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [isPopupId, setPopupId] = useState(null);
  const visiblePageCount = 15;


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (id,event) => {
    setAnchorEl(event.currentTarget);
    setPopupId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    getAllData(1);
  }, [isRefresh]);

  const refreshData = () => {
    setRefresh(!isRefresh);
  };

  const getAllData = async (page) => {
    setLoader(true);
    await axios
      .get(`/api/auth/getAllContactInfo?page=${page}&limit=${visiblePageCount}`)
      .then((res) => {
        if (res.status === 200) {
          setallData(res?.data);
          setLoader(false);
        } else {
          setLoader(false);
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  const handleDeletePopup = (id) => {
    setDelId(id);
    setDeletePopup(true);
  };

  // delete business plan
  const deleteHandler = async (id) => {
    setLoadingBtn(true);
    await axios
      .post("/api/auth/deleteContactInfo", { id: id }, "")
      .then((res) => {
        if (res.status === 200) {
          toast.success("Deleted Successfully !");
          refreshData();
          setDeletePopup(false);
          setLoadingBtn(false);
        } else {
          setLoadingBtn(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoadingBtn(false);
        console.log(e);
      });
  };
  return (
    <>
      {isLoader && <Loader />}
      <div className="p-md-4 p-2 ">
        <div className={style.top_section}>
          <h6 className="mb-0">Contact Details</h6>
        </div>
        <div className="px-2 w-100 pt-4 dashboard_table">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <td>No.</td>
                <td> Name</td>
                <td>Email</td>
                <td>Number</td>
                <td>Subject</td>
                <td>Message</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {allData?.users?.length > 0
                ? allData?.users?.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-3">{index + 1 + (allData?.page - 1) * 10}</td>
                        <td className="py-3">{items?.name}</td>
                        <td className="py-3">{items?.email}</td>
                        <td className="py-3">{items?.number}</td>
                        <td className="py-3">{items?.subject}</td>
                        <td
                          className="py-3 enquery_para"
                          style={{ width: "400px" }}
                        >
                          <div className="d-flex">
                            <p className=""> {items?.message} </p>
                            <div className="custom_popover">
                             {
                              ( items?.message !== null || items?.message  !== "") && 
                              <p
                                aria-describedby={id}
                                onClick={(e) => handleClick(items?._id,e)}
                                className=" btn-link"
                                style={{cursor:"pointer"}}
                              >
                                Read more
                              </p>
                             } 

                              {isPopupId === items?._id && (
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <Typography sx={{ p: 3 }}>
                                    {items?.message}
                                  </Typography>
                                </Popover>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="py-3">
                          <div
                            className="ms-3 btn btn-danger"
                            onClick={() => handleDeletePopup(items?._id)}
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
        <ToastContainer />
        {allData?.totalPages > 1 && (
          <Pagination
            currentpage={allData?.page}
            totalCount={allData?.totalPages}
            visiblePageCount={visiblePageCount}
            getAllData={getAllData}
          />
        )}
      </div>

      {/* =-====== delete popup ========== */}
      <React.Fragment>
        <Modal
          open={isDeletePopup}
          onClose={() => setDeletePopup(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...modalStyle }}  className="custom_mui_del_modal">
            <div className="mt-2 w-100 px-5 py-5">
              <h5 className="text-center pb-4">
                Are you sure you want to delete this User?
              </h5>
              <div className="row justify-content-center align-items-center mt-1">
                <div className="col-md-4 col-12 mt-2">
                  <button
                    onClick={() => setDeletePopup(false)}
                    className="w-100 btn btn-outline-dark"
                  >
                    No
                  </button>
                </div>
                <div className="col-md-4 col-12 mt-2">
                  {isLoadingBtn ? (
                    <LoadingButton
                      loading
                      disabled
                      variant="contained"
                      style={{ background: "#f3f3f3 ", width: "100%" }}
                    >
                      Yes
                    </LoadingButton>
                  ) : (
                    <button
                      onClick={() => deleteHandler(delId)}
                      className="w-100 btn btn-dark"
                    >
                      Yes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
};

import React, { useState } from "react";
import "./style.css";

const PrivacyPolicy = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    mailingAddress: "",
    emailAddress: "",
    number: "",
    employer: "",
    jobTitle: "",
    age: "",
    gender: "",
    race: "",
    religion: "",
    politicalAffiliation: "",
    Household: "",
    income: "",
  });

  return (
    <>
      <section className="policy_content">
        <div className="container">
          <div className="row justify-content-center text-justify">
            <div className="col-md-12">
            <div className="">
              <h1 className="my-1 "> Privacy Policy </h1>
              <p className=" ">
                Protecting your private information is our priority. This
                Statement of Privacy applies to www.michaelpricharda.com, and
                Michael and governs data collection and usage. For the purposes
                of this Privacy Policy, unless otherwise noted, all references
                to Michael include www.michaelpricharda.com, Michael and any of
                their subsidiaries. Michael’s website is a personal
                informational site. By using Michael’s website, you consent to
                the data practices described in this statement.{" "}
              </p>

              <h6 className="">Collection of your Personal Information</h6>
              <p className=" mb-3">
                In order to better provide you with products and services
                offered, Michael may collect personally identifiable
                information, such as your:
              </p>
              <ul className="privacy-list mb-3">
              <li>First and Last Name</li>
              <li>Mailing Address </li>
              <li> E-mail Address</li>
              <li> Phone Number</li>
              <li> Employer</li>
              <li> Job Title </li>
            </ul>
            <p className="mb-3">
              If you purchase Michael's products and services, we collect
              billing and credit card information. This information is used to
              complete the purchase transaction.
            </p>
            <p className="">
              Michael may also collect anonymous demographic information, which
              is not unique to you, such as your:
            <ul className="privacy-list mt-3">
              <li>Age</li>
              <li>Gender </li>
              <li> Race</li>
              <li> Political Affiliation</li>
              <li> Household Income</li>
            </ul>
            </p>
           
            <p className="">
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services. These may include:
              <ul style={{listStyleType:'none'}} className="privacy-list">
                <li> (a) registering for an account;</li>
                <li>
                  {" "}
                  (b) entering a sweepstakes or contest sponsored by us or one
                  of our partners;{" "}
                </li>
                <li>
                  (c) signing up for special offers from selected third parties;
                </li>
                <li> (d) sending us an email message;</li>
                <li>
                  {" "}
                  (e) submitting your credit card or other payment information
                  when ordering and purchasing products and services.
                </li>
              </ul>
              To wit, we will use your information for, but not limited to,
              communicating with you in relation to services and/or products you
              have requested from us. We also may gather additional personal or
              non-personal information in the future.
            </p>
            </div>
           

            <div className="">
              <h6 className="">Use of your Personal Information</h6>
              <p className="">
                Michael collects and uses your personal information to operate
                and deliver the services you have requested. Michael may also
                use your personally identifiable information to inform you of
                other products or services available from Michael and its
                affiliates.
              </p>

              <h6 className="">Sharing Information with Third Parties</h6>
              <p className="">
                Michael does not sell, rent or lease its customer lists to third
                parties. Michael may, from time to time, contact you on behalf
                of external business partners about a particular offering that
                may be of interest to you. In those cases, your unique
                personally identifiable information (e-mail, name, address,
                telephone number) is transferred to the third party. Michael may
                share data with trusted partners to help perform statistical
                analysis, send you email or postal mail, provide customer
                support, or arrange for deliveries. All such third parties are
                prohibited from using your personal information except to
                provide these services to Michael, and they are required to
                maintain the confidentiality of your information.
              </p>
              <p className="">
                Michael may disclose your personal information, without notice,
                if required to do so by law or in the good faith belief that
                such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on Michael or the site;
                (b) protect and defend the rights or property of Michael; and/or
                (c) act under exigent circumstances to protect the personal
                safety of users of Michael, or the public.
              </p>

              <h6 className="">
                Opt-Out of Disclosure of Personal Information to Third Parties
              </h6>
              <p className="mb-1">
                In connection with any personal information we may disclose to a
                third party for a business purpose, you have the right to know:
              </p>
                <p  className="mb-1">
                  The categories of personal information that we disclosed about
                  you for a business purpose.
                </p>
              <p className="">
                You have the right under the California Consumer Privacy Act of
                2018 (CCPA) and certain other privacy and data protection laws,
                as applicable, to opt-out of the disclosure of your personal
                information. If you exercise your right to opt-out of the
                disclosure of your personal information, we will refrain from
                disclosing your personal information, unless you subsequently
                provide express authorization for the disclosure of your
                personal information. To opt-out of the disclosure of your
                personal information email mpricharda@gmail.com.  
              </p>
            </div>

            <div className="">
              <h6 className="">Tracking User Behavior</h6>
              <p className="">
                Michael may keep track of the websites and pages our users visit
                within www.michaelpricharda.com, in order to determine what
                services are the most popular. This data is used to deliver
                customized content and advertising to customers whose behavior
                indicates that they are interested in a particular subject area.
              </p>

              <h6 className="">Automatically Collected Information</h6>
              <p className="">
                Information about your computer hardware and software may be
                automatically collected by Michael. This information can
                include: your IP address, browser type, domain names, access
                times and referring website addresses. This information is used
                for the operation of the service, to maintain quality of the
                service, and to provide general statistics regarding use of the
                CoCreate website.
              </p>

              <h6 className=""></h6>
              <p className="">
                Information about your computer hardware and software may be
                automatically collected by Michael. This information can
                include: your IP address, browser type, domain names, access
                times and referring website addresses. This information is used
                for the operation of the service, to maintain quality of the
                service, and to provide general statistics regarding use of the
                CoCreate website.
              </p>

              <h6 className="">Use of Cookies</h6>
              <p className="">
                Michael‘s website may use "cookies" to help you personalize your
                online experience. A cookie is a text file that is placed on
                your hard disk by a web page server. Cookies cannot be used to
                run programs or deliver viruses to your computer. Cookies are
                uniquely assigned to you, and can only be read by a web server
                in the domain that issued the cookie to you.
              </p>
              <p className="">
                One of the primary purposes of cookies is to provide a
                convenience feature to save you time. The purpose of a cookie is
                to tell the Web server that you have returned to a specific
                page. For example, if you personalize Michael’s pages, or
                register with Michael’ site or services, a cookie helps Michael
                to recall your specific information on subsequent visits. This
                simplifies the process of recording your personal information,
                such as billing addresses, shipping addresses, and so on. When
                you return to the same website, the information you previously
                provided can be retrieved, so you can easily use the features
                that you customized.
              </p>
              <p className="">
                You have the ability to accept or decline cookies. Most Web
                browsers automatically accept cookies, but you can usually
                modify your browser setting to decline cookies if you prefer. If
                you choose to decline cookies, you may not be able to fully
                experience the interactive features of the services or websites
                you visit.
              </p>

              <h6 className="">Links</h6>
              <p className="">
                This website contains links to other sites. Please be aware that
                we are not responsible for the content or privacy practices of
                such other sites. We encourage our users to be aware when they
                leave our site and to read the privacy statements of any other
                site that collects personally identifiable information.
              </p>

              <h6 className="">Security of your Personal Information</h6>
              <p className="">
                Michael secures your personal information from unauthorized
                access, use, or disclosure. Michael uses the following methods
                for this purpose:
              </p>
              <ul className="privacy-list">
                <li>SSL Protocol</li>
              </ul>
              <p>
                When personal information (such as a credit card number) is
                transmitted to other websites, it is protected through the use
                of encryption, such as the Secure Sockets Layer (SSL) protocol.
              </p>
              <p className="">
                We strive to take appropriate security measures to protect
                against unauthorized access to or alteration of your personal
                information. Unfortunately, no data transmission over the
                Internet or any wireless network can be guaranteed to be 100%
                secure. As a result, while we strive to protect your personal
                information, you acknowledge that: (a) there are security and
                privacy limitations inherent to the Internet which are beyond
                our control; and (b) security, integrity, and privacy of any and
                all information and data exchanged between you and us through
                this Site cannot be guaranteed.
              </p>

              <h6 className="">Right to Deletion </h6>
              <p className="mb-2">
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </p>
                <p className="mb-1">Delete your personal information from our records; and </p>
                <p className="mb-2">
                  {" "}
                  Direct any service providers to delete your personal
                  information from their records.
                </p>
              <p className="note">
                * Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to :
                </p>
              <ul className="privacy-list">
                <li>
                  {" "}
                  Complete the transaction for which the personal information
                  was collected, fulfill the terms of a written warranty or
                  product recall conducted in accordance with federal law,
                  provide a good or service requested by you, or reasonably
                  anticipated within the context of our ongoing business
                  relationship with you, or otherwise perform a contract between
                  you and us;{" "}
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity; or prosecute those
                  responsible for that activity;{" "}
                </li>
                <li>
                  {" "}
                  Debug to identify and repair errors that impair existing
                  intended functionality;
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise his or her right of free speech, or exercise another
                  right provided for by law;{" "}
                </li>
                <li>
                  {" "}
                  Comply with the California Electronic Communications Privacy
                  Act;
                </li>
                <li>
                  {" "}
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when our
                  deletion of the information is likely to render impossible or
                  seriously impair the achievement of such research, provided we
                  have obtained your informed consent;
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  your expectations based on your relationship with us{" "}
                </li>
                <li>Comply with an existing legal obligation; or</li>
                <li>
                  {" "}
                  Otherwise use your personal information, internally, in a
                  lawful manner that is compatible with the context in which you
                  provided the information.{" "}
                </li>
              </ul>
             

              <h6 className="">Children Under Thirteen</h6>
              <p className="">
                Michael does not knowingly collect personally identifiable
                information from children under the age of thirteen. If you are
                under the age of thirteen, you must ask your parent or guardian
                for permission to use this website.
              </p>

              <h6 className="">
                Opt-Out & Unsubscribe from Third Party Communications
              </h6>
              <p className="">
                We respect your privacy and give you an opportunity to opt-out
                of receiving announcements of certain information. Users may
                opt-out of receiving any or all communications from third-party
                partners of Michael’s by contacting us here:
              </p>
              <strong>Email: mpricharda@gmail.com</strong>

              <h6 className="">E-mail Communications</h6>
              <p className="">
                {" "}
                From time to time, Michael may contact you via email for the
                purpose of providing announcements, promotional offers, alerts,
                confirmations, surveys, and/or other general communication. In
                order to improve our Services, we may receive a notification
                when you open an email from Michael or click on a link therein.
                If you would like to stop receiving marketing or promotional
                communications via email from Michael, you may opt out of such
                communications by email us at{" "}
                <strong>mpricharda@gmail.com</strong> .{" "}
              </p>

              <h6 className="">External Data Storage Sites</h6>
              <p className="">
                We may store your data on servers provided by third party
                hosting vendors with whom we have contracted.
              </p>

              <h6 className="">Changes to this Statement</h6>
              <p className="">
                Michael reserves the right to change this Privacy Policy from
                time to time. We will notify you about significant changes in
                the way we treat personal information by sending a notice to the
                primary email address specified in your account, by placing a
                prominent notice on our website, and/or by updating any privacy
                information. Your continued use of the website and/or Services
                available after such modifications will constitute your: (a)
                acknowledgment of the modified Privacy Policy; and (b) agreement
                to abide and be bound by that Policy.
              </p>

              <h6 className="">Contact Information</h6>
              <p className="">
                Michael welcomes your questions or comments regarding these
                Terms and Statement of Privacy. If you believe that Michael has
                not adhered to these Terms or this Statement, please contact
                Michael at:
              </p>
            </div>
            <div className="contact">
              <h4 className="mt-4 ">Michael Pricharda</h4>
              <p className="mb-0">534 Walnuts St, Unit 2</p>
              <p className="mb-0">Reading, Pennsylvania 19601</p>
              <p className="mb-0">mpricharda@gmail.com </p>
              <p>484-966-1553</p>
              <p>Effective as of September 01, 2023</p>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;

import React, { Component, useRef, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate, useParams, Link } from "react-router-dom";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";

import "./style.css";
import victor2 from "../../Assets/Vector 1.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// Configure the worker for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function NDAAgreement() {
  const sigRef = useRef();
  const navigate = useNavigate();
  const [signature, setSignature] = useState(null);
  const [isSign, setSign] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isSubmited, setSubmited] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [currentDay, setCurrentDay] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [pdfFile, setPdfFile] = useState({});
  const [pdfBase64URL, setPDFBase64URL] = useState(null);
  const [isClearBtn, setClearBtn] = useState(false);
  const { mail, businessPlan } = useParams();
  const [businessPlanDetail, setBusinessPlanDetail] = useState([]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    // Function to get the current day and date
    const getCurrentDate = () => {
      const now = new Date();
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const day = daysOfWeek[now.getDay()];
      const date = now.toLocaleDateString();

      setCurrentDay(day);
      setCurrentDate(date);
    };
    // Call the function when the component mounts
    getCurrentDate();
  }, []);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };

  const clearSignature = () => {
    sigRef?.current?.clear();
    setSignature(null);
    setSign(false);
  };

  useEffect(() => {
    if (signature !== null) {
      setSign(true);
    }
  }, [signature]);

  useEffect(() => {
    getAllBusinessPlan();
  }, []);

  const getAllBusinessPlan = async () => {
    await axios
      .get(`/api/auth/getAllBusinessPlan`)
      .then((res) => {
        if (res.status === 200) {
          const businessNames = res?.data?.plans?.filter(
            (row) => row?._id === businessPlan
          );
          console.log("businessNames", businessNames[0]?.nda);
          setBusinessPlanDetail(businessNames[0]?.nda);
        } else {
          return;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const generatePDF = async () => {
    if (name === "" || address === "") {
      toast.error("Please fill all feilds");
      setLoader(false);
    } else {
      setClearBtn(true);
      setLoader(true);

      const element = document.getElementById("html-element");
      const pageBreakElements = element.getElementsByClassName("page-break");
      const pageBreakCount = pageBreakElements.length;
      for (let i = 0; i < pageBreakCount; i++) {
        const pageBreakElement = pageBreakElements[i];
        pageBreakElement.style.pageBreakAfter = "always";
      }

      const opt = {
        margin: [10, 10, 10, 10],
        filename: `NDA.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      setPdfFile(opt);
      // Generate the PDF and convert it to base64
      const pdfData = await html2pdf().set(opt).from(element).outputPdf();
      // Convert the PDF data to base64
      const base64PDF = btoa(pdfData);
      uploadImage(opt?.filename, base64PDF);
    }
  };

  // image upload
  const uploadImage = async (filename, dataUrl) => {
    setLoader(true);
    const payload = {
      content: dataUrl,
      fileName: filename,
    };

    const config = { headers: { "Content-Type": "Application/json" } };
    await axios
      .post("/api/auth/uploadFile", payload, config)
      .then((res) => {
        if (res?.status === 200) {
          console.log("res", res?.data?.url);
          handleSubmitAgreement(res?.data?.url);
        } else {
        }
      })
      .catch((err) => {
        toast.error("Can't upload image! try again");
      });
  };

  const handleSubmitAgreement = async (url) => {
    const reqbody = {
      ndaStatus: isSign,
      email: mail,
      pdfBase64Data: url,
      name: name,
      address: address,
      businessID: businessPlan,
    };

    await axios
      .post("/api/auth/agreementStatus", reqbody, "")
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setAlert(true);
          setSubmited(true);
        } else {
          setLoader(false);
          toast.error("Server error");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
        if (e.response.data.message === "User not found") {
          toast.error("Your link is expired . Please try again");
        }
      });
  };

  const handleClose = () => {
    setAlert(false);
    navigate("/");
  };

  return (
    <>
      <section className="agreement_main">
        <div className="container">
          <div className="py-4" id="html-element">
            <div
              className="nda_pdf py-2"
              dangerouslySetInnerHTML={{
                __html: businessPlanDetail,
              }}
            />

            <div className="d-flex justify-content-between pt-5 pb-3 agreements-bottom">
              <div className="signature">
                <p>OWNER:</p>
                <p>
                  By: <strong>Michael Pricharda</strong>{" "}
                </p>
              </div>

              <div className="signature sign-right">
                <p>RECIPIENT:</p>
                <div className="col-12 my-3">
                  <p>
                    By:
                    <input
                      type="text"
                      className=""
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      pattern="^[a-zA-Z][a-zA-Z\s]*$"
                      maxLength={64}
                    />
                  </p>
                </div>
                <div className="col-12 my-3">
                  <input
                    type="text"
                    className=""
                    placeholder="Address, City, State, zip code. "
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    pattern="^[^\s].*"
                    maxLength={100}
                  />
                </div>
                <div className="col-12 my-3">
                  {signature !== null ? (
                    <div className="border ">
                      <img src={signature} alt="" />
                    </div>
                  ) : (
                    <div>
                      <SignatureCanvas
                        penColor="green"
                        canvasProps={{ className: "signature_pad" }}
                        ref={sigRef}
                        onEnd={handleSignatureEnd}
                      />
                    </div>
                  )}
                  {!isClearBtn && (
                    <div
                      className="px-4 text-black "
                      onClick={clearSignature}
                      style={{ cursor: "pointer" }}
                    >
                      <b>
                        <small>Clear</small>{" "}
                      </b>
                    </div>
                  )}
                </div>
                  <div className="col-lg-6 col-md-4 col-10">
                    {isSign ? (
                      <>
                        {isSubmited ? (
                          <button className="btn btn-danger  disabled w-100 ">
                            Submited
                          </button>
                        ) : (
                          <>
                            {isLoader ? (
                              <button className="btn btn-danger buttonload w-100 " style={{display:"none"}}>
                                <i className="fa fa-spinner fa-spin mr-2"></i>{" "}
                                Loading...
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger  w-100 "
                                onClick={generatePDF}
                              >
                                Submit
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <button className="btn btn-danger disabled w-100">
                        Submit
                      </button>
                    )}
                  </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>

      {/* =-======  send Email ========== */}
      <React.Fragment>
        <Modal
          open={isAlert}
          onClose={() => setAlert(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }} className="custom_mui_del_modal">
            <div className={isAlert ? "alert_popup" : ""}>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
              <img src={victor2} style={{ width: "100%" }} />
              <div className="row justify-content-center pb-5">
                <div className="col-10">
                  <h6 className="">
                    {" "}
                    <InfoIcon style={{ marginRight: "5px" }} /> NDA has been
                    sent successfully!
                  </h6>
                  <p className="">
                    Please wait for the access code until the administrator has
                    approved your NDA.
                  </p>
                  <div
                    className="d-flex justify-content-end "
                    onClick={handleClose}
                  >
                    <button className="btn btn-dark px-5">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
}

export default NDAAgreement;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Box, Modal, Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import Pagination from "./Pagination/Index";
import Loader from "../../WebsiteLoader/Index";
import style from "../../Css/admin.module.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

export const Business_page = () => {
  const [allData, setallData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [bname, setBname] = useState("");
  const [desc, setDesc] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [description, setdescription] = useState("");
  const [price, setPrice] = useState("");
  const [isAddPopup, setAddPopup] = useState(false);
  const [isEditPopup, setEditPopup] = useState(false);
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [isRefresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editId, setEditId] = useState("");
  const [businessLabel, setBusinessLabel] = useState("");
  const [pdf, setpdf] = useState("");
  const [isImageUploded, setImageUploded] = useState(false);
  const [isPictureUpload, setPictureUpload] = useState(false);
  const [isLoadingImage, setLoadingImage] = useState(false);
  const [isLoadPicture, setLoadPicture] = useState(false);
  const [pdfBase64URL, setPDFBase64URL] = useState(null);
  const [ImageBase64URL, setImageBase64URL] = useState(null);
  const [nda, setNda] = useState(null);
  const visiblePageCount = "15";

  console.log("editData", editData);

  const closeAddModal = () => {
    setAddPopup(false);
    refreshData();
  };

  useEffect(() => {
    getAllData(1);
  }, [isRefresh]);

  const refreshData = () => {
    setRefresh(!isRefresh);
    setBname("");
    setDesc("");
    setbusinessName("");
    setdescription("");
    setPrice("");
    setBusinessLabel("");
    setpdf("");
    setPDFBase64URL(null);
    setImageUploded(false);
    setLoadPicture(null)
    setPictureUpload(false)
    setImageBase64URL(false)
    setNda("");
  };

  const getAllData = async (page) => {
    setLoading(true);
    await axios
      .get(
        `/api/auth/getAllBusinessPlan?page=${page}&limit=${visiblePageCount}`
      )
      .then((res) => {
        if (res.status === 200) {
          setallData(res?.data);
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  //test this code
  const handleFileUpload = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const base64URL = event.target.result;
        if (base64URL !== null) {
          uploadImage(file, base64URL);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePictureUpload = (event) => {
    console.log(event.target.files)
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const base64URL = event.target.result;
        if (base64URL !== null) {
          uploadPicture(file, base64URL);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const uploadPicture = async (file, dataUrl) => {
    setLoadPicture(true);
    const payload = {
      content: dataUrl,
      fileName: file?.name,
    };

    const config = { headers: { "Content-Type": "Application/json" } };
    await axios
      .post("/api/auth/uploadFile", payload, config)
      .then((res) => {
        if (res?.status === 200) {
          console.log("res", res?.data?.url);
          setPictureUpload(true);
          setImageBase64URL(res?.data?.url);
          setLoadPicture(false);
          toast.success("Image uploaded successfully");
        } else {
          setPictureUpload(false);
          setLoadPicture(false);
        }
      })
      .catch((err) => {
        toast.error("Can't upload image! try again");
        setLoadPicture(false);
      });
  };

  const uploadImage = async (file, dataUrl) => {
    setLoadingImage(true);
    const payload = {
      content: dataUrl,
      fileName: file?.name,
    };

    const config = { headers: { "Content-Type": "Application/json" } };
    await axios
      .post("/api/auth/uploadFile", payload, config)
      .then((res) => {
        if (res?.status === 200) {
          console.log("res", res?.data?.url);
          setImageUploded(true);
          setPDFBase64URL(res?.data?.url);
          setLoadingImage(false);
          toast.success("PDF uploaded successfully");
        } else {
          setImageUploded(false);
          setLoadingImage(false);
        }
      })
      .catch((err) => {
        toast.error("Can't upload image! try again");
        setLoadingImage(false);
      });
  };

  // add new business plan
  const addFormHandler = async (e) => {
    e.preventDefault();

    if (!isImageUploded || desc === "" || nda == null) {
      toast.error("Please fill all the fields");
    } else {
      const reqbody = {
        businessName: bname,
        description: desc,
        price: price,
        businessLabel: businessLabel,
        base64Pdf: pdfBase64URL,
        nda: nda,
        businessImage: ImageBase64URL
      };

      setLoader(true);
      await axios
        .post("/api/auth/addBusinessPlan", reqbody, "")
        .then((res) => {
          if (res.status === 201) {
            toast.success("Added Successfully !");
            setLoader(false);
            setAddPopup(false);
            refreshData();
          } else {
            setLoader(false);
            toast.error("something went wrong try later!");
            return;
          }
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  };

  // handle edit popup
  const handleEditPopup = (data) => {
    setEditData(data);
    setEditPopup(true);
    setEditId(data?._id);
  };

  // update  business plan
  const updateFormHandler = async (e) => {
    e.preventDefault();

    const reqbody = {
      id: editId,
      businessName: businessName || editData?.businessName,
      description: desc || editData?.description,
      price: price || editData?.price,
      businessLabel: businessLabel || editData?.businessLabel,
      base64Pdf: pdfBase64URL || editData?.base64Pdf,
      businessImage: ImageBase64URL || editData?.businessImage,
      nda: nda || editData?.nda,
    };

    setLoader(true);
    await axios
      .post("/api/auth/updateBusinessPlan", reqbody, "")
      .then((res) => {
        if (res.status === 200) {
          toast.success("Updated Successfully !");
          setLoader(false);
          setEditPopup(false);
          refreshData();
        } else {
          setLoader(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const handleDeletePopup = (id) => {
    setEditId(id);
    setDeletePopup(true);
  };

  // delete business plan
  const deleteHandler = async (id) => {
    setLoader(true);
    await axios
      .post("/api/auth/deleteBusinessPlan", { id: id }, "")
      .then((res) => {
        if (res.status === 200) {
          toast.success("Deleted Successfully !");
          setLoader(false);
          refreshData();
          setDeletePopup(false);
        } else {
          setLoader(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="p-md-4 p-2 ">
        <div className={style.top_section}>
          <h6 className="mb-0">Business Plan</h6>
          <button className="btn btn-dark" onClick={() => setAddPopup(true)}>
            Add new plan
          </button>
        </div>
        <div className="px-2 pt-4 dashboard_table">
          <table className="table table-striped ">
            <thead>
              <tr>
                <td>No.</td>
                <td>Plan Title</td>
                <td>Plan Label</td>
                <td>Description</td>
                <td>Image</td>
                <td>Price</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {allData?.plans?.length > 0
                ? allData?.plans?.map((items, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1 + (allData?.page - 1) * 10}</td>
                      <td style={{whiteSpace:"normal"}}>{items?.businessName}</td>
                      <td style={{whiteSpace:"normal"}}>{items?.businessLabel}</td>
                      <td>
                        <div className="business_desc" >{ items?.description}</div>
                        {/* <div
                          className="business_desc"
                          dangerouslySetInnerHTML={{
                            __html: items?.description,
                          }}
                        /> */}
                      </td>
                      <td><div className="" style={{width:'100px'}}> 
                      <img src={items?.businessImage} alt="" />
                      </div></td>
                      <td>{items?.price}</td>
                      <td className="">
                        <div className="d-flex justify-content-start">
                          <div className="">
                            <button
                              className="btn btn-outline-secondary w-100 px-4"
                              onClick={() => handleEditPopup(items)}
                            >
                              Edit
                            </button>
                          </div>
                          <div className="ms-2">
                            <button
                              className="btn btn-danger w-100"
                              onClick={() => handleDeletePopup(items?._id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </table>
          <ToastContainer />

          {allData?.totalPages > 1 && (
            <Pagination
              currentpage={allData?.page}
              totalCount={allData?.totalPages}
              visiblePageCount={visiblePageCount}
              getAllData={getAllData}
            />
          )}
        </div>

        {/* //test this code */}

        {/* =-====== add popup ========== */}
        <React.Fragment>
          <Modal
            open={isAddPopup}
            onClose={() => setAddPopup(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...modalStyle }} className="custom_mui_modal">
              <div className="px-5 py-5">
                <form action="" onSubmit={addFormHandler}>
                  <div className="row">
                    <h4 className="text-center  mb-2">Add Business Plan</h4>
                    <div className="col-12 my-2">
                      <input
                        type="text"
                        value={bname}
                        className="custom_input"
                        placeholder="Enter business plan title"
                        onChange={(e) => setBname(e.target.value)}
                        required
                        minLength={3}
                        maxLength={64}
                      />
                    </div>
                    <div className="col-12 my-2">
                      <input
                        type="text"
                        value={businessLabel}
                        className="custom_input"
                        placeholder="Enter business plan label"
                        onChange={(e) => setBusinessLabel(e.target.value)}
                        required
                        minLength={3}
                        maxLength={64}
                      />
                    </div>
                    <div className="col-12 my-2">
                      <textarea
                        type="text"
                        value={desc}
                        className="custom_input mb-0"
                        placeholder="Enter business plan's description"
                        onChange={(e) => setDesc(e.target.value)}
                        required
                        minLength={3}
                        maxLength={500}
                      >
                      </textarea>
                    </div>
                    <div className="col-12 my-2">
                      <input
                        type="text"
                        value={price}
                        className="custom_input"
                        placeholder="Enter business price"
                        onChange={(e) => setPrice(e.target.value)}
                        required
                        minLength={1}
                        maxLength={15}
                      />
                    </div>
                    <div className="col-12 my-2">
                      <label htmlFor="upload-photo" className="mt-2 w-100">
                        {isLoadPicture ? (
                          <Button
                            variant="outlined"
                            component="span"
                            className="mt-3 w-100"
                          >
                            Uploading ...
                          </Button>
                        ) : (
                          <>
                            {isPictureUpload ? (
                              <Button
                                variant="contained"
                                disabled
                                component="span"
                                className="mt-3 w-100"
                              >
                                Image uploaded
                              </Button>
                            ) : (
                              <>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  className="custom_input"
                                  // accept="application/pdf, application/vnd.ms-excel"
                                  onChange={handlePictureUpload}
                                  required
                                >
                                  Upload Plan's Image
                                  <input
                                    type="file"
                                    hidden
                                  // accept="application/image"
                                  />
                                </Button>
                              </>
                            )}
                          </>
                        )
                        }
                      </label>
                      {isLoadPicture && <div className="bote-bg"> <p className="note"> Note* : Please, Wait until the image uploads</p></div>}
                    </div>


                    <div className="col-12 my-2">
                      <label htmlFor="upload-photo" className="mt-2 w-100">
                        {isLoadingImage ? (
                          <Button
                            variant="outlined"
                            component="span"
                            className="mt-3 w-100"
                          >
                            Uploading ...
                          </Button>
                        ) : (
                          <>
                            {isImageUploded ? (
                              <Button
                                variant="contained"
                                disabled
                                component="span"
                                className="mt-3 w-100"
                              >
                                PDF uploaded
                              </Button>
                            ) : (
                              <>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  className="custom_input"
                                  accept="application/pdf, application/vnd.ms-excel"
                                  onChange={handleFileUpload}
                                  required
                                >
                                  Enter plan's pdf
                                  <input
                                    type="file"
                                    hidden
                                    accept="application/pdf,application/vnd.ms-excel"
                                  />
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </label>
                      {isLoadingImage && <div className="bote-bg"> <p className="note"> Note* : Please, Wait until the pdf uploads</p></div>}

                    </div>

                    {/* NDA  start*/}

                    <div className="col-12 my-2">
                      <label htmlFor="upload-photo" className="w-100 mb-2">
                        Enter NDA agreement Data
                      </label>
                      <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CKEditor
                            editor={ClassicEditor}
                            data="<p>Enter more info here ..</p>"
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setNda(data);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    {/* NDA  end*/}
                    <div className="row justify-content-center align-items-center mt-3">
                      <div className="col-md-6 col-12 mt-2">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          onClick={closeAddModal}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-6 col-12 mt-2">
                        {isLoader ? (
                          <button
                            type="button"
                            className="btn inactive_btn w-100"
                          >
                            Loading..
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-dark w-100">
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </React.Fragment>

        {/* =-====== edit popup ========== */}
        <React.Fragment>
          <Modal
            open={isEditPopup}
            onClose={() => setEditPopup(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...modalStyle }} className="custom_mui_modal">
              <div className="px-5 py-5">
                <form action="" onSubmit={updateFormHandler}>
                  <div className="row">
                    <h4 className="text-center mb-3">Edit Business Plan</h4>
                    <div className="col-12 my-2">
                    <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change business plan's title
                      </label>
                      <input
                        type="text"
                        defaultValue={
                          editData?.businessName
                            ? editData?.businessName
                            : bname
                        }
                        className="custom_input"
                        placeholder="Enter business plan title"
                        onChange={(e) => setbusinessName(e.target.value)}
                        required
                        minLength="3"
                      />
                    </div>
                    <div className="col-12 my-2">
                    <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change business plan's label
                      </label>
                      <input
                        type="text"
                        defaultValue={
                          editData?.businessLabel
                            ? editData?.businessLabel
                            : businessLabel
                        }
                        className="custom_input"
                        placeholder="Enter business plan label"
                        onChange={(e) => setBusinessLabel(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 my-2">
                    <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change business plan's description
                      </label>
                      <textarea
                        type="text"
                        defaultValue={
                          editData?.description
                            ? editData?.description
                            : desc
                        }
                        className="custom_input px-2 py-2"
                        placeholder="Enter business plan's description"
                        onChange={(e) => setDesc(e.target.value)}
                        required
                        style={{height:'130px', overflowY:"scroll"}}
                      >
                      </textarea>
                    </div>
                   
                    <div className="col-12 my-2">
                    <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change business plan's price
                      </label>
                      <input
                        type="text"
                        defaultValue={editData?.price}
                        className="custom_input"
                        placeholder="Enter business price"
                        onChange={(e) => setPrice(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 my-2">
                    <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change business plan's image
                      </label>
                      <label htmlFor="upload-photo" className="mt-2 w-100">
                        {isLoadPicture ? (
                          <Button
                            variant="outlined"
                            component="span"
                            className="mt-3 w-100"
                          >
                            Uploading ...
                          </Button>
                        ) : (
                          <>
                            {isPictureUpload || editData?.businessImage ? (
                              <Button
                                variant="outlined"
                                component="label"
                                className="custom_input"
                                onChange={handlePictureUpload}
                              >
                                Change Plan's Image
                                <input
                                  type="file"
                                  hidden
                                  accept=""
                                />
                              </Button>
                            ) : (
                              <>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  className="custom_input"
                                  // accept="application/pdf, application/vnd.ms-excel"
                                  onChange={handlePictureUpload}
                                  required
                                >
                                  Upload Plan's Image
                                  <input
                                    type="file"
                                    hidden
                                  // accept="application/image"
                                  />
                                </Button>
                              </>
                            )}
                          </>
                        )
                        }
                      </label>
                    </div>
                    <div className="col-12 my-2">
                    <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change business plan's PDF
                      </label>
                      <label htmlFor="upload-photo" className="mt-2 w-100">
                        <>
                          {isLoadingImage ? (
                            <Button
                              variant="outlined"
                              disable
                              component="span"
                              className="mt-3 w-100"
                            >
                              Uploading ...
                            </Button>
                          ) : (
                            <>
                              {isImageUploded || editData?.base64Pdf ? (
                                <Button
                                  variant="outlined"
                                  component="label"
                                  className="custom_input"
                                  onChange={handleFileUpload}
                                >
                                  Change plan's pdf
                                  <input
                                    type="file"
                                    hidden
                                    accept="application/pdf,application/vnd.ms-excel"
                                  />
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  component="label"
                                  className="custom_input"
                                  onChange={handleFileUpload}
                                >
                                  Enter plan's pdf
                                  <input
                                    type="file"
                                    hidden
                                    accept="application/pdf,application/vnd.ms-excel"
                                  />
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      </label>
                    </div>

                    <div className="col-12 my-2">
                      <label htmlFor="upload-photo" className=" w-100 mb-2">
                        Change NDA data
                      </label>
                      <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={editData?.nda}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setNda(data);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div className="row justify-content-center align-items-center mt-5">
                      <div className="col-md-6 col-12 mt-2">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          onClick={() => setEditPopup(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-md-6 col-12 mt-2">
                        {isLoader ? (
                          <button
                            type="button"
                            className="btn inactive_btn w-100"
                          >
                            Loading..
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-dark w-100">
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </React.Fragment>

        {/* =-====== delete popup ========== */}
        <React.Fragment>
          <Modal
            open={isDeletePopup}
            onClose={() => setDeletePopup(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...modalStyle }} className="custom_mui_del_modal">
              <div className="mt-2 w-100 px-5 py-5">
                <h5 className="text-center pb-4">
                  Are you sure you want to delete this Plan?
                </h5>
                <div className="row justify-content-center align-items-center mt-1">
                  <div className="col-md-4 col-6 mt-2">
                    <button
                      onClick={() => setDeletePopup(false)}
                      className="w-100 btn btn-outline-dark"
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-4 col-6 mt-2">
                    {isLoader ? (
                      <LoadingButton
                        loading
                        disabled
                        variant="contained"
                        style={{ background: "#f3f3f3 ", width: "100%" }}
                      >
                        Yes
                      </LoadingButton>
                    ) : (
                      <button
                        onClick={() => deleteHandler(editId)}
                        className="w-100 btn btn-dark"
                      >
                        Yes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </React.Fragment>
      </div>
    </>
  );
};

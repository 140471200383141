import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import LoadingButton from "@mui/lab/LoadingButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SendIcon from "@mui/icons-material/Send";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TuneIcon from "@mui/icons-material/Tune";

import style from "../../Css/admin.module.css";
import Loader from "../../WebsiteLoader/Index";
import Pagination from "./Pagination/Index";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  border: "none",
  height: "90vh",
  overflowY: "scroll",
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const UserList = () => {
  const [allData, setallData] = useState([]);
  const [allUser, setAllUsers] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isRefresh, setRefresh] = useState(false);
  const [delId, setDelId] = useState("");
  const [isDeletePopup, setDeletePopup] = useState(false);
  const visiblePageCount = 15;
  const [businessPlans, setBusinessPlan] = useState(["All"]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [isPDfView, setPDfView] = useState(false);
  const [userDetails, setuserDetails] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElBusinessPlan, setanchorElBusinessPlan] = React.useState(null);
  const open = Boolean(anchorEl);
  const openBusinessPlan = Boolean(anchorElBusinessPlan);
  const [isOpenAccess, setOpenAccess] = useState(false);
  const [AccessCodeUSer, setAccessCodeUSer] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBusinessPlanClick = (event) => {
    setanchorElBusinessPlan(event.currentTarget);
  };

  const handleBusinessPlanClose = () => {
    setanchorElBusinessPlan(null);
  };

  useEffect(() => {
    getAllData(1);
  }, [isRefresh]);

  useEffect(() => {
    getAllBusinessPlan();
  }, []);

  const refreshData = () => {
    setRefresh(!isRefresh);
  };

  const getAllData = async (page) => {
    setLoader(true);
    await axios
      .get(`/api/auth/getAllUser?page=${page}&limit=${visiblePageCount}`)
      .then((res) => {
        if (res.status === 200) {
          setallData(res?.data);
          setAllUsers(res?.data?.data?.slice().reverse());
          setLoader(false);
        } else {
          setLoader(false);
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const getAllBusinessPlan = async () => {
    await axios
      .get(`/api/auth/getAllBusinessPlan`)
      .then((res) => {
        if (res.status === 200) {
          const businessNames = res?.data?.plans?.map(
            (row) => row?.businessName
          );

          setBusinessPlan(["All", ...businessNames]);
        } else {
          return;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeletePopup = (id) => {
    setDelId(id);
    setDeletePopup(true);
  };

  // delete business plan
  const deleteHandler = async (id) => {
    setLoadingBtn(true);
    await axios
      .post("/api/auth/deleteUser", { id: id }, "")
      .then((res) => {
        if (res.status === 200) {
          toast.success("Deleted Successfully !");
          refreshData();
          setDeletePopup(false);
          setLoadingBtn(false);
        } else {
          setLoadingBtn(false);
          toast.error("something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoadingBtn(false);
        console.log(e);
      });
  };

  // send access code

  const handleAccessPopup = (row) => {
    setOpenAccess(true);
    setAccessCodeUSer(row);
  };

  const sendAccessCode = async () => {
    setLoadingBtn(true);
    const reqbody = {
      email: AccessCodeUSer?.email,
      _id: AccessCodeUSer?._id,
    };
    await axios
      .post("/api/auth/inviteUser", reqbody, "")
      .then((res) => {
        if (res.status === 200) {
          toast.success("Access code send succefully  !");
          setLoadingBtn(false);
          setOpenAccess(false);
          refreshData();
        } else {
          setLoadingBtn(false);
          toast.error("Something went wrong try later!");
          return;
        }
      })
      .catch((e) => {
        setLoadingBtn(false);
        console.log(e);
      });
  };

  // check pdf
  const handleViewPdf = (row) => {
    setPDfView(true);
    setuserDetails(row?.pdf_url);
  };

  // filter by signed
  const handleFilter = (status) => {
    if (status === "All") {
      setAllUsers(allData?.data);
    } else {
      const filterArr = allData?.data?.filter((row, inx) => {
        return row?.ndaStatus === status;
      });
      setAllUsers(filterArr);
    }
    handleBusinessPlanClose();
  };

  // filter by business plan
  const handlePlanFilter = (bPlan) => {
    // getAllBusinessPlan();

    if (bPlan === "All") {
      setAllUsers(allData?.data);
    } else {
      const filterArr = allData?.data?.filter((row, inx) => {
        return row?.businessName === bPlan;
      });
      setAllUsers(filterArr);
    }
    handleClose();
  };

  return (
    <>
      {isLoader && <Loader />}
      <div className="p-md-4 p-2 ">
        <div className={style.top_section}>
          <h6 className="mb-0">All Users</h6>
          <div className="d-flex justify-content-end mt-1">
          <div className="">
            <button
              id="filter-button"
              aria-controls={open ? "filter-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="btn btn-outline-dark"
            >
              Filter By Business Plan <TuneIcon style={{ color: "black" }} />
            </button>
            <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "filter-button",
              }}
            >
              {businessPlans?.map((plan, inx) => (
                <MenuItem key={inx} onClick={() => handlePlanFilter(plan)}>
                  {plan}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div className="ms-4">
            <button
              id="business-plan-button"
              aria-controls={
                openBusinessPlan ? "business-plan-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openBusinessPlan ? "true" : undefined}
              onClick={handleBusinessPlanClick}
              className="btn btn-outline-dark"
            >
              Filter by signed <TuneIcon style={{ color: "black" }} />
            </button>
            <Menu
              id="business-plan-menu"
              anchorEl={anchorElBusinessPlan}
              open={openBusinessPlan}
              onClose={handleBusinessPlanClose}
              MenuListProps={{
                "aria-labelledby": "business-plan-button",
              }}
            >
              <MenuItem onClick={() => handleFilter("All")}>All</MenuItem>
              <MenuItem onClick={() => handleFilter(true)}>Signed</MenuItem>
              <MenuItem onClick={() => handleFilter(false)}>
                Not Signed
              </MenuItem>
            </Menu>
          </div>
        </div>
        </div>

      
        <div className="px-2 w-100 dashboard_table">
          <table className="table table-striped table-bordered  ">
            <thead>
              <tr>
                <td>No.</td>
                <td>Email</td>
                <td>Business Name</td>
                <td>NDA Status</td>
                <td>Address</td>
                <td> Access Code</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {allUser?.length > 0
                ? allUser?.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1 + (allData?.page - 1) * 10}</td>
                        <td>{items?.email}</td>
                        <td>{items?.businessName}</td>
                        <td>
                          {items?.ndaStatus ? (
                            <div className="d-flex align-items-center ">
                              <DoneIcon
                                style={{
                                  color: "green",
                                  cursor: "pointer",
                                  marginRight: "7px",
                                }}
                              />
                              Signed
                            </div>
                          ) : (
                            <div className="d-flex align-items-center ">
                              <CloseIcon
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginRight: "7px",
                                }}
                              />
                              Not Signed
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="business_desc users_desc">
                            <p className="mb-0 pb-0">
                              {items?.address ? items?.address : "-"}
                            </p>
                          </div>
                        </td>
                        <td>
                          {items?.ndaStatus ? (
                            <>
                              {items?.Accesskey ? (
                                <>
                                  <div className="d-flex align-items-center ">
                                    <CircleIcon
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                        marginRight: "7px",
                                      }}
                                    />
                                    Code Sent
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="btn btn-light"
                                  onClick={() => handleAccessPopup(items)}
                                >
                                  Send Access Code
                                  <SendIcon
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            `-`
                          )}
                        </td>
                        <td className="py-3">
                         <div className="d-flex">
                         <LightTooltip title="Delete " placement="top-end">
                            <div
                              className="ms-3"
                              onClick={() => handleDeletePopup(items?._id)}
                            >
                              <DeleteOutlineIcon
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </div>
                          </LightTooltip>
                          {items?.ndaStatus && (
                            <LightTooltip
                              title="View NDA agreement"
                              placement="top"
                            >
                              <Link
                                className="ms-3"
                                style={{ textDecoration: "none" }}
                                target="blank"
                                to={items?.pdf_url}
                              >
                                <VisibilityIcon style={{ cursor: "pointer" }} />
                              </Link>
                            </LightTooltip>
                          )}
                         </div>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
        {allData?.totalPages > 1 && (
          <Pagination
            currentpage={allData?.page}
            totalCount={allData?.totalPages}
            visiblePageCount={visiblePageCount}
            getAllData={getAllData}
          />
        )}

        <ToastContainer />
      </div>

      {/* =-====== delete popup ========== */}
      <React.Fragment>
        <Modal
          open={isDeletePopup}
          onClose={() => setDeletePopup(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...modalStyle }} className="custom_mui_del_modal">
            <div className="mt-2 w-100 px-5 py-5">
              <h5 className="text-center pb-4">
                Are you sure you want to delete this User?
              </h5>
              <div className="row justify-content-center align-items-center mt-1">
                <div className="col-md-4 col-12 mt-2">
                  <button
                    onClick={() => setDeletePopup(false)}
                    className="w-100 btn btn-outline-dark"
                  >
                    No
                  </button>
                </div>
                <div className="col-md-4 col-12 mt-2">
                  {isLoadingBtn ? (
                    <LoadingButton
                      loading
                      disabled
                      variant="contained"
                      style={{ background: "#f3f3f3 ", width: "100%" }}
                    >
                      Yes
                    </LoadingButton>
                  ) : (
                    <button
                      onClick={() => deleteHandler(delId)}
                      className="w-100 btn btn-dark"
                    >
                      Yes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>

      {/* =-====== NDA show popup ========== */}
      <React.Fragment>
        <Modal
          open={isPDfView}
          onClose={() => setPDfView(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...modalStyle }}>
            <div className="mt-2 w-100 px-2 py-2">
              <iframe
                title="PDF Viewer"
                src={`data:application/pdf;base64,${userDetails}`}
                width="800px"
                height="700px"
                className="custom_pdf_viewer"
              />
            </div>
          </Box>
        </Modal>
      </React.Fragment>

      {/* =-====== Send Accesscode popup ========== */}
      <React.Fragment>
        <Modal
          open={isOpenAccess}
          onClose={() => setOpenAccess(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...modalStyle }} className="custom_mui_del_modal">
            <div className="mt-2 w-100 px-5 py-5">
              <h5 className="text-center pb-4">
                Are you sure you want to send access code to this user?
              </h5>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-md-4 col-12">
                  <button
                    onClick={() => setOpenAccess(false)}
                    className="w-100 btn btn-outline-dark"
                  >
                    No
                  </button>
                </div>
                <div className="col-md-4 col-12">
                  {isLoadingBtn ? (
                    <LoadingButton
                      loading
                      disabled
                      variant="contained"
                      style={{ background: "#f3f3f3 ", width: "100%" }}
                    >
                      Yes
                    </LoadingButton>
                  ) : (
                    <button
                      onClick={sendAccessCode}
                      className="w-100 btn btn-dark"
                    >
                      Yes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
};

import React from "react";
// import "./style.css";
import "./loader.css";

const Loader = () => {
  return (
    <>
     <div className="loader-container">
        <div className="spinner"></div>
      </div>
    </>
  );
};

export default Loader;

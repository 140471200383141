import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import InfoIcon from '@mui/icons-material/Info';

import victor2 from "../../Assets/Vector 1.png";
import SendIcon from "./assets/send";
import "../style.css"
import { Cursor } from "mongoose";

const SendMail = ({ businessPlan, closeModal }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [isAlert, setAlert] = useState(false);

  console.log(businessPlan)
  const hideModal = () => {
    closeModal();
  };

  //test this code
  const submitEmail = async (e) => {
    e.preventDefault();

    const reqbody = {
      businessID: businessPlan?._id,
      email: email,
    };

    setLoader(true);
    await axios
      .post("/api/auth/sendInvite", reqbody, "")
      .then((res) => {
        if (res.status === 200) {
          setAlert(true);
          setLoader(false);
          // setTimeout(() => {
          //   hideModal();
          // }, 2000);
        } else if (res?.status === 404) {
          toast.error(res?.response?.data?.message);
        } else {
          setLoader(false);
          toast.error(res?.response?.data?.message);
          return;
        }
      })
      .catch((e) => {
        setLoader(false);
        toast.error(e?.response?.data?.message);
        console.log(e);
      });
  };

  return (
    <>
      <div className={isAlert ? "alert_popup" : ""}>
        <div className=""    style={{cursor:"pointer"}}>
        <button
          type="button"
          className="btn-close"
          onClick={hideModal}
          style={{cursor:"pointer", zIndex:"99999"}}
        ></button>
        </div>
        {isAlert ? (
          <>
            <img src={victor2} style={{ width: "100%" }} />
            <div className="row justify-content-center pb-5">
              <div className="col-10">
                <h6 className="d-flex align-items-center"> <InfoIcon style={{ marginRight: "3px" }} /> Mail sent successfully!</h6>
                <p className="">
                  Please check your mail and sign the NDA Agreement to get access code to our business plan.
                </p>
                <div
                  className="d-flex justify-content-end mt-2"
                  onClick={hideModal}
                >
                  <button className="btn btn-dark px-5">Ok</button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container-fluid">
            <div className="plan_details_inner">
            {/* <img src={victor2} style={{ width: "100%" }} className="popup_img" /> */}
              <div className="row business_detail_popup py-5 px-3">
                <div className="col-md-3 col-12 ">
                  <img src={businessPlan?.businessImage} alt="" />
                </div>
                <div className="col-md-9 col-12 pr-3">
                  <h4 className="mb-0">{businessPlan?.businessName}</h4>
                  {/* <h6 className="">{businessPlan?.businessLabel}</h6> */}
                  <h6 className="mb-0">{businessPlan?.price} / per month</h6>
                  <p className="">{businessPlan?.description}</p>
                 
                </div>
                <div className="col-12">
                    <form action="" className="" onSubmit={submitEmail}>
                      <label id="B-plan-label"> Enter your email </label>
                      <div className="business_row mt-2">
                        <input
                          required
                          type="email"
                          id="B-plan-field"
                          className="custom_input mb-0"
                          placeholder="Enter your email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {isLoader ? (
                          <button type="button" id="icon" >
                            <i className="fa fa-spinner fa-spin text-light"></i>
                          </button>
                        ) : (
                          <button type="submit" id="icon"  >
                            <SendIcon style={{ height: "20px", width: "20px" }} />
                            {/* Send */}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
              </div>
            </div>
            </div>
          </>
        )}

      </div>

    </>
  );
};

export default SendMail;
